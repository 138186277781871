import { createTheme } from '@mui/material/styles';
import { blue, green, orange, red } from '@mui/material/colors';
import { SettingsManager } from 'services';

export const createMuiTheme = (defaultTheme, themes) => {

  const themeByDefault = themes.find((theme) => defaultTheme === theme.name);
  const configuredTheme = themes.find((theme) => SettingsManager.theme === theme.name);
  const theme = configuredTheme ? configuredTheme : themeByDefault;
  const themeColors = theme?.properties;

  const muiTheme = createTheme({
    palette: {
      // Main color (ex: buttons color)
      primary: {
        main: themeColors.colorSuggested,
        light: themeColors.colorSelected,
        dark: themeColors.colorHovered,
        contrastText: themeColors.colorTextSuggested
      },
      // Main Background Color (ex: Navigation & TopBar)
      secondary: {
        main: themeColors.colorBackgroundPrimary,
        contrastText: themeColors.colorTextBackgroundPrimary
      },
      // Lighter Background Color (ex: display of most components)
      tertiary: {
        main: themeColors.colorBackgroundSecondary,
        contrastText: themeColors.colorTextBackgroundSecondary,
      },
      // Gobal status colors for the app
      error: {
        lighter: '#fdecea', // Color from in MUI alerts background, good constrast with red[500]
        light: red[300],
        main: red[500],
        dark: red[700],
        darker: red[900]
      },
      warning: {
        light: orange[500],
        main: orange[700],
        dark: orange[900]
      },
      info: {
        light: blue[300],
        main: blue[700],
        dark: blue[900]
      },
      success: {
        lighter: green[300],
        light: green[500],
        main: green[800],
        dark: green[900],
        darker: green[900]
      }
    },
    // Global variables for main layouts to be shared between components
    topBar: {
      height: '80px'
    }
  })

  return muiTheme
}