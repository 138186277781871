import { Typography, Button, Switch, Toolbar, Menu, MenuItem } from '@mui/material';
import {React, useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'utils';
import {Groups as GroupsIcon,
    Notifications as NotificationsIcon,
    Summarize as SummarizeIcon,
    Undo as UndoIcon,
    Share as ShareIcon
} from '@mui/icons-material';
import { KeycloakManager } from 'services';
import { useTheme } from '@mui/styles';

function toNowDateTimeString() {
    const date = new Date();
    return {
        time: date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }), 
        date:  date.toLocaleDateString([],{
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric"
      })
    };
}

function DateTime() {
    const [dateTime, setDateTime] = useState(toNowDateTimeString);

    useEffect(() => {
        const interval = setInterval(() => {
            setDateTime(toNowDateTimeString());
        }, 1000);
        return () => clearInterval(interval);
      }, []);

    return (<div style={{textAlign: 'center', margin: 'auto 0'}}>
            <Typography variant="h5" >{dateTime.time}</Typography>
            <Typography variant="subtitle2" >{dateTime.date}</Typography>
       </div>);
}

function TopBar() {
    const theme = useTheme();
    const [shareToggle, setShareToggle] = useState(true);
    const [groupVisibile, setGroupVisible] =useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleGroupsClick = (e) => {
      setAnchorEl(e.currentTarget);
      setGroupVisible(!groupVisibile)
    }
    const handleGroupsClose = (e) => {
      setGroupVisible(false)
      setAnchorEl(null);
    }

    return <Toolbar style={{gap:'10px', padding: '0 10px 0 0', backgroundColor: theme.palette.secondary.main, color : theme.palette.secondary.contrastText}}>
        <img width="80px" src="img/crimson_avatar.png" alt=''/>
        <DateTime />
        <Button 
          variant="contained"
          startIcon={<GroupsIcon />}
          aria-controls={groupVisibile ? 'group-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={groupVisibile ? 'true' : undefined}
          onClick={(e) => handleGroupsClick(e)}
        >
          Groupes
        </Button>
        <Menu
          id="group-menu"
          anchorEl={anchorEl}
          open={groupVisibile}
          onClose={handleGroupsClose}
          sx={{width: '100%'}}
        >
          {KeycloakManager.getUserGroups().map((group) => (
            <MenuItem key={group} onClick={handleGroupsClose}>{group}</MenuItem>
          ))}
        </Menu>
        <div style={{flexGrow:1}} />
        <Button sx={{display: shareToggle ? 'none' : 'visible'}} variant="contained" startIcon={<UndoIcon />}>Annuler</Button>
        <Button sx={{display: shareToggle ? 'none' : 'visible'}} variant="contained" startIcon={<ShareIcon />}>Partager</Button>
        <Typography>Auto-partage</Typography>
        <Switch checked={shareToggle} onClick={() => setShareToggle(!shareToggle)}>Auto-partage</Switch>
        <Button variant="contained" startIcon={<SummarizeIcon />}>Synthèse</Button>
        <Button variant="contained" startIcon={<NotificationsIcon />}>Notifications</Button>
    </Toolbar>;
}

export default compose(
    withTranslation('common')
  )(TopBar);