import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { compose } from 'utils';
import { withAppConfig } from 'AppConfig';
import { withAnnotations } from 'CopContext';
import Map from 'components/Map';
import AboutDialog from 'components/AboutDialog';
import DrawerMenu from 'components/DrawerMenu/DrawerMenu';
import Navigation from 'components/Navigation/Navigation';
import OfflineIndicator from 'components/OfflineIndicator/OfflineIndicator';
import StopSessionIndicator from 'components/StopSessionIndicator/StopSessionIndicator';
import TopBar from 'components/TopBar';
import { SettingsManager } from 'services';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '80px'
    }
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  '@keyframes flash': {
    '0%': {
      opacity: 1
    },
    '60%': {
      opacity: 1
    },
    '100%': {
      opacity: 0
    }
  },
  topbar: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    height: theme.topBar.height,
    width: '100%',
    display: theme.topBar.display
  },
});

/**
 * Main view.
 */
class Main extends React.PureComponent {
  state = {
    aboutDialogOpen: false,
    successiveClicks: 0,
    drawerOpen: false,
    navigationVisible: true
  };

  handleSettingsPageDisplayRequest = () => {
    this.setState({
      aboutDialogOpen: false
    });
    this.props.history.push('server-settings');
  };

  componentDidUpdate(_, prevState) {
    // Secret menu ??
    if (prevState.successiveClicks < this.state.successiveClicks) {
      clearTimeout(this.resetClicksTimeout);
      this.resetClicksTimeout = setTimeout(() => {
        this.setState({ successiveClicks: 0 });
      }, 3000);
      if (this.state.successiveClicks === 7) {
        this.setState({ aboutDialogOpen: false });
        this.props.setWidgetStatus({ open: false, callback: null });
        this.handleSettingsPageDisplayRequest();
      }
    }
  }

  backFunction = () => {
    this.props.history.goBack();
  };

  toggleAboutDialog = () => {
    this.setState(
      (state) => ({ aboutDialogOpen: !state.aboutDialogOpen }),
      () => {
        this.props.setWidgetStatus({
          open: this.state.aboutDialogOpen,
          callback: this.state.aboutDialogOpen ? this.externallyCloseAboutDialog : null
        });
      }
    );
  };

  externallyCloseAboutDialog = () => {
    this.setState({ aboutDialogOpen: false });
  };

  handleLogoClick = () => {
    this.setState((state) => ({
      successiveClicks: state.successiveClicks + 1
    }));
  };

  setNavigationVisible = (bool) => {
    this.setState({
      navigationVisible: bool
    });
  };

  /**
   * Render the main component.
   */
  render() {
    const { navigationVisible } = this.state;
    const {
      history,
      /*ready,*/ session,
      onLogout,
      onChangeSession,
      user,
      setWidgetStatus,
      classes,
      online,
      appConfig
    } = this.props;
    
  /**
   * Add routes for plugins
   */
  const pluginRoutes = appConfig.mainNavigationRoutes.map((params) => {
      const Component = params.component;
      return (
        <Route
          path={'/main' + params.path}
          key={params.path}
          render={(props) => <Component {...props} setWidgetStatus={setWidgetStatus} />}
        />
      )});

    return (
      <div className={classes.container}>
        {appConfig.managers.map((Component, index) => (
          <Component key={index} history={history} />
        ))}
        { SettingsManager.topBar && (
          <div className={classes.topbar}>
          <TopBar />
        </div>
        )}
        <div className={classes.content}>
          {/* Drawer will be hidden in Web View */}
          <div className={classes.drawer}>
            <DrawerMenu
              user={user}
              session={session}
              onLogout={onLogout}
              onChangeSession={onChangeSession}
              toggleAboutDialog={this.toggleAboutDialog}
              setWidgetStatus={setWidgetStatus}
            />
          </div>
          
          <Switch>
            {/* If page has been refreshed, redirect to last visited page */}
            <Route exact path="/main" render={() => <Redirect to={sessionStorage.getItem('persistentPage') || "/main/map"} />} />
            <Route
              path="/main/map"
              render={() => (
                <Map
                  user={user}
                  setWidgetStatus={setWidgetStatus}
                  online={online}
                  setNavigationVisible={this.setNavigationVisible}
                />
              )}
            />
            {pluginRoutes}
          </Switch>
        </div>
        <OfflineIndicator online={online} />
        <StopSessionIndicator onChangeSession={onChangeSession}/>        
        {navigationVisible === true && <Navigation onLogout={onLogout}/>}
        <AboutDialog
          open={this.state.aboutDialogOpen}
          handleClose={this.toggleAboutDialog}
          handleLogoClick={this.handleLogoClick}
        />
      </div>
    );
  }
}

Main.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired
  }).isRequired,
  session: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  onChangeSession: PropTypes.func.isRequired,
  user: PropTypes.node.isRequired
};

export default compose(
  withStyles(styles),
  withRouter,
  withAppConfig,
  withTranslation('common'),
  withAnnotations({
    mapAnnotationsToProps: () => ({})
  })
)(Main);
