/**
 * @param {Object} params
 * @param {string} params.action
 * @param {Object} params.event
 * @param {Object} params.annotation
 * @param {string} params.user
 * @param {Object=} params.oldValue
 * @param {number=} params.priority
 * @param {boolean=} params.optimistic
 * @param {*=} params.subject
 * @param {Function=} params.successHandler
 * @param {Function=} params.errorHandler
 *
 * @returns {Object}
 */

const handleCommandQueueUpdate = ({
  action,
  annotation,
  priority,
  optimistic,
  successHandler,
  errorHandler,
  conflictHandler
}) => (state) => {
  const { commandQueue, unsyncedAnnotations } = state;

  const newState = {
    commandQueue: [],
    willRetry: false
  };

  // If we already have a command with the same key in the queue we want to remove it
  newState.commandQueue = commandQueue.filter(
    (command) => !command.annotation || command.annotation.Uuid !== annotation.Uuid
  );

  // And then add the latest command at the end of the queue
  newState.commandQueue.push({
    annotation,
    priority: priority,
    action,
    successHandler,
    errorHandler,
    conflictHandler
  });

  // We need to keep track of unsynced annotations separately because actual annotations are overridden every time we receive a new event from the server
  if (optimistic === true) {
   
    newState.unsyncedAnnotations = {
      ...unsyncedAnnotations,
      [annotation.type]: {
        ...unsyncedAnnotations[annotation.type],
        [annotation.Uuid]: {
          annotation: {
            ...annotation,
            synced: false
          },
          priority: priority
        }
      }
    };
  }

  return newState;
};

export default handleCommandQueueUpdate;
