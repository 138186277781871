import React from 'react';
import { withStyles } from '@mui/styles';
import {
  Divider,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Radio,
  RadioGroup,
  Switch,
  TextField
} from '@mui/material';
import GeoLocationManager from 'services/GeoLocationManager';
import { compose } from 'utils';
import i18n, { _t } from 'utils/i18n';
import { withMapManager } from 'MapManagerContext';
import ViewLayout from 'components/ViewLayout/ViewLayout';
import { withAppConfig } from 'AppConfig';

const styles = theme => ({
  settingsContainer: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
    height: '100%'
  },
  secondaryText: {
    color: theme.palette.tertiary.contrastText
  },
  radioContainer: {
    marginLeft: 0
  },
  textField: {
    margin: '1em',
    width: '20em'
  }
});

class SettingsView extends React.Component {
  constructor() {
    super();

    /**
     *  The identifier for watching location
     */
    this.watchLocationId = -1;

    /**
     * The state for settings
     * @type {Object}
     */
    this.state = {
      geoPosition: '',
      accuracy: '',
      language: i18n.language.slice(0, 2),
      // backgroundLocation: false,
      // backgroundTrackingInterval: GeoLocationManager.backgroundTrackingInterval,
      nbBackgroundLocationUpdates: GeoLocationManager.nbBackgroundUpdates,
      nbForegroundLocationUpdates: GeoLocationManager.nbForegroundUpdates,
      teamLocationActivityInterval: GeoLocationManager.geoPosRefreshInterval / 1000 // Convert from ms to s
    };
  }

  componentDidMount() {
    // this.setState({ backgroundTrackingInterval: GeoLocationManager.backgroundTrackingInterval });

    this.watchLocationId = GeoLocationManager.watchLocation(
      (latitude, longitude, accuracy) => {
        this.setState({
          nbBackgroundLocationUpdates: GeoLocationManager.nbBackgroundUpdates,
          nbForegroundLocationUpdates: GeoLocationManager.nbForegroundUpdates,
          geoPosition: `${longitude.toFixed(6)} ${latitude.toFixed(6)}`,
          accuracy: Math.round(accuracy)
        });
      },
      { background: true }
    );

    GeoLocationManager.getBackgroundStatus((status) => this.setState({ backgroundLocation: status.isRunning }));
  }

  componentWillUnmount() {
    GeoLocationManager.clearWatch(this.watchLocationId);
  }

  /**
   * Change the language
   */
  changeLanguage = (event) => {
    const { value } = event.target;

    i18n.changeLanguage(value === 'fr' ? 'fr-FR' : value);
    this.setState({
      language: value
    });
  };
  handleTeamLocationActivityInterval = (event) => {
    const { value } = event.target;

    this.setState({ teamLocationActivityInterval: value });

    if (value >= 1 && value < 10e3) {
      // Convert from seconds to ms
      GeoLocationManager.geoPosRefreshInterval = value * 1000;
      console.info('Info: updated GeoLocationManager.geoPosRefreshInterval to', GeoLocationManager.geoPosRefreshInterval);
    }
  };

  // handleBackgroundTrackingIntervalChange = (event) => {
  //   const { value } = event.target;
  //   this.setState({ backgroundTrackingInterval: value });

  //   if (value > 0) {
  //     GeoLocationManager.backgroundTrackingInterval = value;
  //   } else {
  //     GeoLocationManager.backgroundTrackingInterval = 30;
  //   }
  // };

  // changeBackgroundLocation = (event) => {
  //   const { checked } = event.target;
  //   this.setState({ backgroundLocation: checked });
  //   if (checked) {
  //     GeoLocationManager.startBackground((status) => this.setState({ backgroundLocation: status.isRunning }));
  //   } else {
  //     GeoLocationManager.stopBackground();
  //   }
  // };

  render() {
    const { classes, appConfig } = this.props;

    return (
      <ViewLayout title={_t('Settings')} className={classes.settingsContainer}>
        <div className={classes.settingsContainer} style={{width: {xs: '100%', lg: '50%'}}}>
        <List>
          <ListSubheader className={classes.settingsContainer}>{_t('Language')}</ListSubheader>
          <RadioGroup name="language" value={this.state.language} onChange={this.changeLanguage}>
            <FormControlLabel
              value="fr"
              control={<Radio color="primary" />}
              label={_t('Français')}
              className={classes.radioContainer}
            />
            <FormControlLabel
              value="en"
              control={<Radio color="primary" />}
              label={_t('English')}
              className={classes.radioContainer}
            />
          </RadioGroup>
        </List>
        <Divider />
        <List>
          <ListSubheader className={classes.settingsContainer}>{_t('Location')}</ListSubheader>
          <ListItem>
            <ListItemText classes={{secondary: classes.secondaryText}} primary={_t('Position')} secondary={this.state.geoPosition} />
          </ListItem>
          <ListItem>
            <ListItemText classes={{secondary: classes.secondaryText}} primary={`${_t('Accuracy')} (m)`} secondary={this.state.accuracy} />
          </ListItem>

          {/* On any GPS location change dectected, which is the minimum interval between requests to update team activity date */}
          <TextField
            label={_t('TeamGPSUpdateDate')}
            type="number"
            variant="filled"
            required
            inputProps={{
              min: 1,
              max: 10e3
            }}
            className={classes.textField}
            value={this.state.teamLocationActivityInterval}
            onChange={this.handleTeamLocationActivityInterval}
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">{_t('Seconds')}</InputAdornment> }}
            error={this.state.teamLocationActivityInterval < 1 || this.state.teamLocationActivityInterval > 10e3}
            sx={{backgroundColor: (theme) => theme.palette.common.white, borderRadius: '5px'}}
          />

          {/* Deprecated */}
          {/* <ListItem>
            <ListItemText primary={_t('Background Tracking')} />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.changeBackgroundLocation}
                checked={this.state.backgroundLocation}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem> */}
          {/* <TextField
            id="standard-name"
            label={`${_t('Tracking Interval')} (s)`}
            type="number"
            variant="outlined"
            className={classes.textField}
            value={this.state.backgroundTrackingInterval}
            onChange={this.handleBackgroundTrackingIntervalChange}
            disabled={this.state.backgroundLocation}
            margin="normal"
          /> */}
          <ListItem>
            <ListItemText
              classes={{secondary: classes.secondaryText}}
              primary={_t('Location Updates')}
              secondary={`${this.state.nbForegroundLocationUpdates} / ${this.state.nbBackgroundLocationUpdates}`}
            />
          </ListItem>
        </List>
        <Divider />
        {/*
         * Hide "Map" settings for now
         */}
        {/* <List>
          <ListSubheader>{_t('Map')}</ListSubheader>
          <ListItem>
            <ListItemText primary="3D" />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.props.MapManager.toggleUse3D}
                checked={this.props.MapManager.use3D}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List> */}
        <List sx={{width: {xs: '100%', lg: '400px'}}}>
          <ListSubheader className={classes.settingsContainer}>{_t('Background Mode')}</ListSubheader>
          <ListItem>
            <ListItemText primary="Activation" />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.props.onBackgroundModeChanged}
                checked={this.props.backgroundModeEnabled}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        {appConfig.settingItems}
      </div>
    </ViewLayout>
      
    );
  }
}

export default compose(withStyles(styles), withMapManager, withAppConfig)(SettingsView);
