/* eslint-disable camelcase */
import Keycloak from 'keycloak-js';
import { ServerSettingsManager } from 'services';
import SettingsManager from './SettingsManager';

/**
 * Keeps the keycloak state and does periodic queries to update the tokens.
 */
class KeycloakManager {
  getAccessHeaders = () => {
    const lResult = new Headers({
      Authorization: `Bearer ${this.getAccessToken()}`
    });
    return lResult;
  };

  directLogout = () => {
    clearInterval(this.refreshId);
    window.localStorage.setItem('state', '');
    window.sessionStorage.setItem('state', '');
  };

  directRefreshToken = async (onSessionExpired) => {
    const url = ServerSettingsManager.keycloakUrl;
    const realm = ServerSettingsManager.keycloakRealm;
    const clientId = ServerSettingsManager.keycloakClientId;

    const body = new URLSearchParams({
      client_id: clientId,
      refresh_token: this.tokens.refresh_token,
      grant_type: 'refresh_token'
    });

    try {

      const response = await fetch(`${url}/realms/${realm}/protocol/openid-connect/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body
      });

      if (response.ok) {
        this.tokens = await response.json();
      } else {
        clearInterval(this.refreshId);
        onSessionExpired();
      }
    } catch (err) {
      if (err.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        clearInterval(this.refreshId);
        onSessionExpired();
      } else if (err.request) {
        // The request was made but no response was received
        console.error('Error: KeycloakManager.directRefreshToken - no response from server.');
      } else {
        // Offline or connection reset
        console.error('Error: KeycloakManager.directRefreshToken - disconnected.');
      }
    };
  }

  /**
   * @param {string} user
   * @param {string} password
   * @param {boolean} rememberme
   *
   * @returns {Promise<void>}
   */
  directLogin = async ({ user, password, rememberme, onSessionExpired }) => {
    const url = ServerSettingsManager.keycloakUrl;
    const realm = ServerSettingsManager.keycloakRealm;
    const clientId = ServerSettingsManager.keycloakClientId;

    this.mAdminUrl = `${url}/admin/realms/${realm}`;

    let body;

    const keycloakScope = SettingsManager.keycloak.scope !== undefined ? SettingsManager.keycloak.scope : "offline_access";

    if (!password) {
      let refreshToken = window.sessionStorage.getItem('state');
      if (!refreshToken) {
        refreshToken = window.localStorage.getItem('state');
        if (!refreshToken) {
          throw new Error('No token : please re-enter credentials');
        }
      }
      body = new URLSearchParams({
        client_id: clientId,
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        scope: keycloakScope
      });
    } else {
      body = new URLSearchParams({
        username: user,
        password: password,
        client_id: clientId,
        grant_type: 'password',
        scope: keycloakScope
      });
    }
    // Get the token from keycloak
    const response = await fetch(`${url}/realms/${realm}/protocol/openid-connect/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body
    });

    if (response.ok) {
      this.tokens = await response.json();

      window.sessionStorage.setItem('state', this.tokens.refresh_token);
      if (rememberme) {
        window.localStorage.setItem('state', this.tokens.refresh_token);
      }

      this.refreshId = setInterval(() => this.directRefreshToken(onSessionExpired), this.tokens.expires_in * 900);

      // Refresh the token on resume with cordova
      if (window.cordova) {
        document.addEventListener("resume", () => {
          this.directRefreshToken(onSessionExpired);
       });
      }

      this.user = await this.loadUserInfo();
      this.userGroups = await this.loadUserGroups();
      this.userRoles = await this.loadUserRoles();

    } else {
      throw new Error('Invalid url ' + response.status);
    }
  };

  /**
   * Login to keycloak using Javascript adapter
   */
  login({ onAuthError }) {
    const url = ServerSettingsManager.keycloakUrl;
    const realm = ServerSettingsManager.keycloakRealm;
    const clientId = ServerSettingsManager.keycloakClientId;

    this.mAdminUrl = `${url}/admin/realms/${realm}`;

    // Create the Javascript adapter for keycloak
    this.mKeycloakAdapter = new Keycloak({ url, realm, clientId });

    return new Promise((resolve, reject) => {
      // Function called when keycloaks has successfully been authentificated
      const onSuccess = () => {
        // Launch the update token periodically
        this.mRefreshIntervalId = setInterval(() => {
          this.mKeycloakAdapter.updateToken(ServerSettingsManager.refreshTokenInterval);
        }, ServerSettingsManager.refreshTokenInterval * 1000);

        this.mKeycloakAdapter.onAuthRefreshError = () => {
          if (onAuthError) {
            onAuthError();
          }
        };

        this.mKeycloakAdapter.onTokenExpired = () => {
          if (onAuthError) {
            onAuthError();
          }
        };
        // Load the user info
        this.mKeycloakAdapter
          .loadUserInfo()
          .success((pUser) => {
            this.user = pUser;

            this.loadUserGroups()
              .then((pResult) => (this.userGroups = pResult))
              .finally(() => resolve());
          })
          .error((e) => {
            reject(new Error(`Could not retrieve user infos: ${e}`));
          });
      };

      // Init keycloak adapter and check-sso
      this.mKeycloakAdapter
        .init({ onLoad: 'check-sso', checkLoginIframe: false })
        .success((pAuthenticated) => {
          if (pAuthenticated) {
            onSuccess();
          } else {
            this.mKeycloakAdapter
              .login()
              .success(() => {
                onSuccess();
              })
              .error((e) => {
                reject(new Error(e));
              });
          }
        })
        .error((e) => {
          reject(new Error(e));
        });
    });
  }

  /**
   * Logout from keycloak
   */
  logout() {
    clearInterval(this.mRefreshIntervalId);
    return this.mKeycloakAdapter.logout();
  }

  /**
   * Get the keycloak access token.
   * @return {String} The access token.
   */
  getAccessToken() {
    return this.tokens ? this.tokens.access_token : this.mKeycloakAdapter.token;
  }

  /**
   * Get the user infos.
   *
   * @return {Object} The user infos.
   */
  getUser() {
    return this.user;
  }

  /**
   * Get the user groups.
   * @return {Object} The user groups.
   */
  getUserGroups() {
    const set = this.userGroups.reduce((s, g) => {
      const groups = g.path.split('/');
      groups.forEach((item) => {
        if (item) {
          s.add(item);
        }
      });
      return s;
    }, new Set());
    return Array.from(set);
  }

  /**
   * Load the groups of the user
   *
   * @return {Promise}	The groups of the url
   */
  async loadUserGroups() {
    const lUrl = `${this.mAdminUrl}/users/${this.user.sub}/groups`;
    const lHeader = this.getAccessHeaders();
    const lResponse = await fetch(lUrl, { headers: lHeader });
    const lGroups = await lResponse.json();
    return lGroups;
  }

  /**
   * Load the user info
   *
   * @return {Promise}	The groups of the url
   */
  async loadUserInfo() {
    const url = ServerSettingsManager.keycloakUrl;
    const realm = ServerSettingsManager.keycloakRealm;
    const lUrl = `${url}/realms/${realm}/protocol/openid-connect/userinfo`;
    const lHeader = this.getAccessHeaders();
    const lResponse = await fetch(lUrl, { headers: lHeader });
    const lUser = await lResponse.json();
    return lUser;
  }

  async getUsersList() {
    const lUrl = `${this.mAdminUrl}/users`;
    const lHeader = this.getAccessHeaders();
    const lNbResponse = await fetch(lUrl +"/count", { headers: lHeader });

    const lNbUsers = await lNbResponse.json();
    const lNbUsersPerPage = 50.0
    const lNbPage = Math.ceil(lNbUsers / lNbUsersPerPage);
    let lUsers = [];
    for (let lIdxPage = 0; lIdxPage < lNbPage; ++lIdxPage)
    {
        const lResponse = await fetch(lUrl + "?max="+lNbUsersPerPage+"&first="+lIdxPage*lNbUsersPerPage, { headers: lHeader });
        lUsers = lUsers.concat(await lResponse.json());
    }
    return lUsers;
  }

  async loadUserRoles() {
    const lClientUrl = `${this.mAdminUrl}/clients`;
    const lHeader = this.getAccessHeaders();

    const lClientResponse = await fetch(lClientUrl, { headers: lHeader });
    const lClients = await lClientResponse.json();

    const lCrimsonServerClient = lClients.find((pClient) => pClient.clientId === 'CrimsonServer');

    const lUrl = `${this.mAdminUrl}/users/${this.user.sub}/role-mappings/clients/${lCrimsonServerClient.id}/composite`;
    const lResponse = await fetch(lUrl, { headers: lHeader });
    const lRoles = await lResponse.json();

    return lRoles.reduce((pSet, pRole) => pSet.add(pRole.name), new Set());
  }

  async getUserInfos(pUserId) {
    const lUrl = `${this.mAdminUrl}/users/${pUserId}`;
    const lHeader = this.getAccessHeaders();
    const lResponse = await fetch(lUrl, { headers: lHeader });
    const lInfos = await lResponse.json();

    return lInfos;
  }
}

/**
 * KeycloakManager singleton.
 */
export default new KeycloakManager();
