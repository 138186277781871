import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { withRouter } from 'utils/withRouter';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  ChevronLeft as ChevronLeftIcon,
  PhotoCamera as PhotoCameraIcon,
  Videocam as VideocamIcon,
  PhotoLibrary as PhotoLibraryIcon
} from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { withNetworkManager } from 'NetworkManagerContext';
import { withCop } from 'CopContext';
import { compose } from 'utils';
import { GeoLocationManager, KeycloakManager } from 'services';
import { _t } from 'utils/i18n';
import { showUserGeoLocationError } from 'utils/locationUtils';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText
  },
  content: {
    flex: '1 1 auto',
    padding: '1em'
  },
  imageInput: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0
  },
  imagePreview: {
    display: 'block',
    maxWidth: '90%',
    maxHeight: '250px',
    margin: 'auto',
    padding: '1em'
  },
  validContainer: {
    [theme.breakpoints.up('lg')] : {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
  },
  messageField: {
    [theme.breakpoints.up('lg')] : {
      width: '50%'
    },
    width: '100%',
    marginTop: '1rem'
  },
  button: {
    [theme.breakpoints.up('lg')] : {
      width: '50%'
    },
    width: '100%',
    marginTop: '1.5rem'
  },
  successMsg: {
    color: green[500]
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main
  },
  toolbarTitle: {
    marginRight: '0.75rem',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center'
  },
  toolbarButton: {
    color: theme.palette.common.white,
    marginRight: '1rem',
    marginLeft: '-1rem'
  }
});

class Photo extends React.Component {
  /**
   * The state for photo report
   * @type {Object}
   */
  state = {
    mediaURL: '',
    /**
     * @type {"VIDEO"|"PICTURE"}
     */
    mediaType: '',
    mediaMimeType: '',
    dialogOpen: false,
    waiting: false,
    done: false,
    // Comment text
    text: '',
    sendingStatus: ''
  };

  componentDidMount() {
    if (!window.cordova) {
      this.mediaInput.click();
    }
  }

  /**
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.willRetry && this.props.willRetry) {
      this.setState({
        done: true,
        waiting: false,
        text: '',
        mediaURL: '',
        sendingStatus: _t('Photo send retry')
      });
    }
  }

  /**
   * Upload a media on the web app
   */
  handleWebUpload = (evt) => {
    if (!window.cordova) {
      if (evt.target && evt.target.files && evt.target.files[0]) {
        const file = evt.target.files[0];
        if (file.type.startsWith('video')) {
          this.setState({
            mediaURL: URL.createObjectURL(file),
            mediaMimeType: file.type,
            mediaType: 'VIDEO'
          });
        } else if (file.type.startsWith('audio')) {
          this.setState({
            mediaURL: URL.createObjectURL(file),
            mediaMimeType: file.type,
            mediaType: 'AUDIO'
          });
        } else {
          const urlReader = new FileReader();

          urlReader.onload = (e) => {
            this.setState({
              mediaURL: e.target.result,
              mediaMimeType: file.type,
              mediaType: 'PICTURE'
            });
          };

          if (evt.target && evt.target.files && evt.target.files[0]) {
            urlReader.readAsDataURL(evt.target.files[0]);
          }
        }
      }
    }
  };

  /**
   * Take a picture using cordova
   */
  cordovaTakePicture = (from = 'camera') => {
    if (window.cordova) {
      const onSuccess = (pImageData) => {
        this.setState({
          mediaURL: `data:image/jpeg;base64,${pImageData}`,
          mediaType: 'PICTURE',
          mediaMimeType: 'image/jpeg'
        });
      };

      const onFail = (pMessage) => {
        // Ignore no image selected error (The report is not completed and the user hasn't left the screen)
        if (pMessage.toLowerCase() !== 'no image selected') {
          this.setState({
            done: true,
            waiting: false,
            sendingStatus: `Cannot take picture : ${pMessage}`
          });
        }
      };

      navigator.camera.getPicture(onSuccess, onFail, {
        quality: 50,
        correctOrientation: true,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        cameraType: navigator.camera.MediaType.PICTURE,
        sourceType:
          from === 'library'
            ? navigator.camera.PictureSourceType.PHOTOLIBRARY
            : navigator.camera.PictureSourceType.CAMERA
      });
    }
  };

  cordovaRecordVideo = () => {
    try {
      if (window.cordova) {
        const captureSuccess = (mediaFiles) => {
          const path = mediaFiles[0].fullPath;

          // Get native URL to be able to preview the video
          window.resolveLocalFileSystemURL(
            path,
            (fileEntry) => {
              console.log('Photo -> captureSuccess -> fileEntry', fileEntry.toURL());
              this.setState({ mediaURL: fileEntry.toURL(), mediaType: 'VIDEO', mediaMimeType: mediaFiles[0].type });
            },
            () => console.error('Cannot access to ' + path)
          );
        };

        const captureError = (error) => {
          navigator.notification.alert('Error code: ' + error.code, null, 'Capture Error');
        };

        const permissions = window.cordova.plugins.permissions;
        permissions.requestPermission(permissions.READ_EXTERNAL_STORAGE, (status) => {
          if (status.hasPermission) {
            navigator.device.capture.captureVideo(captureSuccess, captureError, { limit: 1, duration: 60, quality: 1 });
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleSuccess = (comment, incident) => {
    return async (response) => {
      try {

        if (response.status >= 300) {
          this.setState({
            done: true,
            waiting: false,
            sendingStatus: 'Sending failed, please check your connection and try again later.'
          });
        } else {
          // Now create the annotation and send it to COP...
          const lAssetPath = response.headers.get('Location');
          if (!lAssetPath) {
            throw new Error('Internal Server error : response not valid, asset location is not found');
          }

          // If no incident is selected
          if (!incident) {

            // Commit an IdPhoto annotation

            // Retreive coordinates from GPS
            // Note : a timeout is used, so the call can fail
            const {
              coords: { latitude, longitude }
            } = await GeoLocationManager.getLocation();

            const lMediaName = `${KeycloakManager.getUser().preferred_username} ${new Date().toLocaleString()}`;

            let lAnnotation = {};

            // Create GeoMedia annotation for photo, video and audio
            lAnnotation = {
              AssetPaths: [lAssetPath],
              Name: lMediaName,
              tags: ['GeoMedia', 'Maps'],
              LayerTag: 'GeoMedia',
              FeatureGeometry: {
                type: 'PointGeometry',
                Point: [longitude.toString(), latitude.toString(), '0']
              },
              type: 'GeoMedia',
              GeoSymbolType: this.state.mediaType === 'VIDEO' ? 
              'crimson.symbol.media.video' : (
                this.state.mediaType === 'AUDIO' ?
                'crimson.symbol.media.audio' : 'crimson.symbol.media.photo'
                ),
              Uuid: uuidv4()
            };

            this.props.addAnnotation(lAnnotation);

            // and join the comment to the IdPhoto annotation
            if (comment && comment.length > 0) {
              // Hack because CopContext comand process doesn't properly support command flood
              setTimeout(
                () =>
                  this.props.addAnnotation({
                    Comment: comment,
                    CommentedUUID: lAnnotation.Uuid,
                    AssetPaths: [],
                    OwnerUUID: KeycloakManager.getUser().sub,
                    emitter: KeycloakManager.getUser().preferred_username,
                    Timestamp: new Date().toISOString(),
                    type: 'Comment',
                    tags: ['Comment']
                  }),
                3000
              );
            }
          } else {
            // If an incident is selected, join the comment with the photo to this one
            this.props.addAnnotation({
              Comment: comment,
              CommentedUUID: incident.Uuid,
              AssetPaths: [lAssetPath],
              OwnerUUID: KeycloakManager.getUser().sub,
              emitter: KeycloakManager.getUser().preferred_username,
              type: 'Comment',
              tags: ['Comment']
            });
          }

          // Time out so the user cna see the success msg
          setTimeout(
            () =>
              // Go back to the incident
              this.props.history.goBack(),
            2000
          );

          this.setState({
            done: true,
            waiting: false,
            text: '',
            mediaURL: '',
            sendingStatus: _t('Media send success')
          });
        }
      } catch (geoLocationPositionError) {
        this.setState({ dialogOpen: false, waiting: false });

        showUserGeoLocationError(geoLocationPositionError.code);
      }
    };
  };

  handleError = (error) => {
    console.error(error);

    this.setState({
      done: true,
      waiting: false,
      text: '',
      mediaURL: '',
      sendingStatus: _t('Photo send fail')
    });
  };

  /**
   * Send video blod
   */
  sendVideoBlob = (blob) => {
    const { text } = this.state;
    const { location, sendAsset } = this.props;
    const incident = location && location.state ? location.state.incident : undefined;

      try {
        sendAsset(blob, this.handleSuccess(text, incident), this.handleError, 'blob');
      } catch (err) {
        console.error(err);
      }
  };

  /**
   * Handle when user click on send
   */
  handleSend = () => {
    try {
      this.setState({ dialogOpen: true, waiting: true });

      const { mediaURL, mediaType, text } = this.state;
      const { location, sendAsset } = this.props;

      const incident = location && location.state ? location.state.incident : undefined;

      if (mediaType === 'PICTURE' || mediaType === '') {
        sendAsset(mediaURL, this.handleSuccess(text, incident), this.handleError);
      } else if (mediaType === 'VIDEO' || mediaType === 'AUDIO') {
        if (window.cordova) {
          // Read from file system and then send file to server
          window.resolveLocalFileSystemURL(mediaURL, (fileEntry) => fileEntry.file(this.sendVideoBlob));
        } else {
          // Read from object URL and then send file to server
          fetch(mediaURL)
            .then((response) => response.blob())
            .then(this.sendVideoBlob);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false
    });
  };

  /**
   * Render the component
   */
  render() {
    const { done, mediaType, mediaMimeType, mediaURL } = this.state;
    const { classes } = this.props;

    let lDialogTitle = '';
    let lDialog = '';
    let lDialogActions = null;
    if (this.state.waiting) {
      lDialogTitle = _t('Sending photo report');
      lDialog = <CircularProgress />;
    } else if (done) {
      lDialogActions = (
        <Button variant="contained" color="primary" onClick={this.handleClose}>
          {_t('Close')}
        </Button>
      );
      lDialogTitle = '';
      lDialog = this.state.sendingStatus;
    }
    return (
      <div className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            tooltip={_t('Back')}
            onClick={this.props.history.goBack}
            className={classes.toolbarButton}
            size="large">
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
          <Typography variant="h6" className={classes.toolbarTitle}>
            {_t('Photo Report')}
          </Typography>
        </Toolbar>
        <div id="photo" className={classes.content}>
          {!window.cordova ? (
            <input
              ref={(input) => (this.mediaInput = input)}
              className={classes.imageInput}
              type="file"
              accept="image/*, video/*"
              capture
              onChange={this.handleWebUpload}
            />
          ) : (
            mediaURL === '' &&
            done === false && (
              <>
                <Grid container justifyContent="center">
                  <Grid item xs={10} sm={6} md={4}>
                    <Grid container spacing={4} direction="column" alignItems="start">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<PhotoCameraIcon />}
                          onClick={this.cordovaTakePicture}
                          fullWidth
                        >
                          {_t('TakePhotoReport')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<PhotoLibraryIcon />}
                          onClick={() => this.cordovaTakePicture('library')}
                          fullWidth
                        >
                          {_t('ImportExistingReport')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<VideocamIcon />}
                          onClick={this.cordovaRecordVideo}
                          fullWidth
                        >
                          {_t('TakeVideoReport')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )
          )}
          {mediaURL !== '' && (
            <>
              {/* Image or video preview */}
              {mediaType === 'VIDEO' ? (
                <video controls className={classes.imagePreview} controlsList="nodownload">
                  <source src={mediaURL} type={mediaMimeType} />
                </video>
              ) : mediaType === 'AUDIO' ? (
                <audio controls className={classes.imagePreview} controlsList="nodownload">
                  <source src={mediaURL} type={mediaMimeType} />
                </audio>
              ) : (
                <img src={mediaURL} className={classes.imagePreview} alt="report preview" />
              )}
              <div className={classes.validContainer}>
                <TextField
                  variant="standard"
                  label={_t('Please enter your message')}
                  value={this.state.text}
                  onChange={(event) => this.setState({ text: event.target.value })}
                  className={classes.messageField} />
                  <Button variant="contained" className={classes.button} fullWidth onClick={this.handleSend}>
                    {_t('Send')}
                  </Button>
              </div>
            </>
          )}
        </div>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          disableEscapeKeyDown>
          <DialogTitle>{lDialogTitle}</DialogTitle>
          <DialogContent>{lDialog}</DialogContent>
          <DialogActions>{lDialogActions}</DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(withNetworkManager, withRouter, withStyles(styles), withCop)(Photo);
