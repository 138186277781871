import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Main from 'components/Main';
import Photo from 'components/Photo';
import Audio from 'components/Audio';
import Login from 'components/Login';
import Loading from 'components/Loading';
import SessionSelection from 'components/SessionSelection';
import ServerSettingsComponent from 'components/ServerSettingsComponent';
import GeoPosCopElement from 'components/GeoPosCopElement';
import TeamSelection from 'components/TeamSelection';
import SettingsView from 'components/SettingsView';
import IncidentElement from 'Plugins/IncidentPlugin/IncidentElement';
import { CopProvider, CopContext } from 'CopContext';
import { MapManagerProvider } from 'MapManagerContext';
import { AppConfigContext } from 'AppConfig';
import { KeycloakManager, SettingsManager } from 'services';
import withSnackNotifications from '../CopContext/withSnackNotifications';

/**
 * Component used to manage the initial routing of the application
 */
const InitialRouting = ({ teamRegistration }) => {
  const { ready, team } = React.useContext(CopContext);

  let from = '/';
  let to = '/';

  if (!ready) {
    to = '/loading';
  } else if (!team && teamRegistration && KeycloakManager.userRoles.has('PR_CanHaveTeam')) {
    from = '/loading';
    to = '/team';
  } else {
    from = '/:foo(team|loading)';
    to = '/main';
  }
  return <Redirect from={from} to={to} />;
};

/**
 * Render the app, conditionnally rendering according to login state
 */

const Routes = ({
  user,
  session,
  loginError,
  waiting,
  selfStyledObjects,
  backgroundModeEnabled,
  teamRegistration,
  onLogin,
  clearLoginError,
  handleSessionSelected,
  endTeamRegistration,
  goBack,
  handleBackgroundModeChanged,
  handleLogout,
  hideServerSettings,
  handleChangeSession,
  setWidgetStatus,
  showServerSettings,
  widgetStatus,
  snackNotify
}) => {
  const [redirection, setRedirection] = React.useState('');
  const [PluginRoutes, setPluginRoutes] = React.useState(null);

  const [successiveBacks, setSuccessiveBacks] = React.useState(0);
  const resetBackTimeoutRef = React.useRef(null);

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('common');
  const appConfig = useContext(AppConfigContext);

  /**
   * Add routes for active plugins
   */
  const addPluginRoutes = React.useCallback(() => {
    const newPluginRoutes = appConfig.topLevelRoutes.map((pParams) => {
      const Component = pParams.component;
      return (
        <Route
          path={pParams.path}
          key={pParams.path}
          render={(props) => <Component {...props} setWidgetStatus={setWidgetStatus} />}
        />
      );
    });

    setPluginRoutes(newPluginRoutes);
  }, [setWidgetStatus, appConfig]);

  React.useEffect(() => {
    if (user && session) {
      addPluginRoutes();
      setRedirection('/loading');
    } else if (user) {
      setRedirection('/session');
    } else if (showServerSettings) {
      setRedirection('/server-settings');
    } else {
      if (SettingsManager?.keycloakLogin || waiting) {
        setRedirection('/loading');
      } else {
        setRedirection('/login');
      }
    }
  }, [addPluginRoutes, session, showServerSettings, user, waiting]);

  /**
   * Handle "back" button press on phones
   * Moved from "App" because it doesn't have access to tolcation and history
   */
  const handleBackButtonPress = React.useCallback(() => {
    if (widgetStatus.open) {
      if (typeof widgetStatus.callback === 'function') {
        widgetStatus.callback();
      }
      setWidgetStatus({ open: false, callback: null });
    } else if (location && (!session || location.pathname === '/main/map')) {
      setSuccessiveBacks((prevState) => prevState + 1);
    } else {
      if (history) {
        history.push('/main/map');
      } else {
        console.error('history is undefined.');
      }
    }
  }, [history, location, session, setWidgetStatus, widgetStatus]);

  /**
   * On mount, add back button and refresh event listeners
   */
  React.useEffect(() => {
    document.addEventListener('backbutton', handleBackButtonPress);
    window.onbeforeunload = () => {
      // Kepp the last visited page in memory when refreshing
      sessionStorage.setItem('persistentPage',location.pathname)
    };
    return function cleanup() {
      document.removeEventListener('backbutton', handleBackButtonPress);
    };
  }, [handleBackButtonPress]);

  /**
   * Handle successive back button presses timeout
   */
  React.useEffect(() => {
    if (successiveBacks === 1) {
      const message = session ? t('Will exit on next tap') : t('Will go back to login on next tap');
      snackNotify(message, {
        priority: 100,
        duration: 3000,
        forceBottom: !session
      });
      resetBackTimeoutRef.current = setTimeout(() => {
        setSuccessiveBacks(0);
      }, 3000);
    } else if (successiveBacks === 2) {
      if (session) {
        navigator.app.exitApp();
      } else {
        setSuccessiveBacks(0);
        (async function effect() {
          await handleLogout();
        })();
      }
    }

    return function cleanup() {
      if (resetBackTimeoutRef.current) {
        clearTimeout(resetBackTimeoutRef.current);
        resetBackTimeoutRef.current = null;
      }
    };
  }, [handleLogout, session, snackNotify, successiveBacks, t]);

  return (
    <>
      <Route path="/loading" component={Loading} />
      <Route path="/login">
        <Login onLogin={onLogin} loginError={loginError} eraseError={clearLoginError} />
      </Route>
      <Route path="/session">
        <SessionSelection user={user} onSubmit={handleSessionSelected} onBack={goBack} />
      </Route>
      <Route path="/server-settings">
        <ServerSettingsComponent
          login={() => {
            hideServerSettings();
            onLogin();
          }}
        />
      </Route>
      <Redirect to={redirection} />

      {user && session ? (
        <CopProvider session={session}>
          <MapManagerProvider selfStyledObjects={selfStyledObjects}>
            <GeoPosCopElement backgroundModeEnabled={backgroundModeEnabled} />
            <IncidentElement />
            {!sessionStorage.getItem('persistentPage') ? (
              <InitialRouting teamRegistration={teamRegistration} />
            ):(<InitialRouting teamRegistration={false} />)}

            <Switch>
              <Route path="/settings">
                <SettingsView
                  backgroundModeEnabled={backgroundModeEnabled}
                  onBackgroundModeChanged={handleBackgroundModeChanged}
                />
              </Route>
              <Route path="/photo" component={Photo} />
              <Route path="/audio" component={Audio} />
              <Route path="/team">
                <TeamSelection
                  user={user}
                  onFinish={() => {
                    endTeamRegistration();
                    sessionStorage.removeItem('persistentPage')
                    history.push('/main');
                  }}
                />
              </Route>

              {PluginRoutes}
              <Route path="/main">
                <Main
                  session={session}
                  user={user.name || user.preferred_username}
                  onLogout={handleLogout}
                  onChangeSession={handleChangeSession}
                  setWidgetStatus={setWidgetStatus}
                />
              </Route>
            </Switch>
          </MapManagerProvider>
        </CopProvider>
      ) : null}
    </>
  );
};

// TODO: props
Routes.propTypes = {};

export default withSnackNotifications(Routes);
