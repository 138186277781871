/**
 * General purpose JavaScript utility functions
 */

/**
 * This utility is shamelessly copied from recompose to allow
 * a more readable HoC composition. See :
 * https://github.com/acdlite/recompose/blob/master/src/packages/recompose/compose.js
 */
export const compose = (...funcs) =>
  funcs.reduce(
    (a, b) => (...args) => a(b(...args)),
    (arg) => arg
  );

/**
 * Fastest way to deep clone an object. Doesn't handle corner cases and may transform properties with complex types.
 * @param {Object} obj
 * @return {Object}
 */
export const deepClone = (obj) => {
  const newObj = obj === undefined ? {} : JSON.parse(JSON.stringify(obj));
  return newObj;
};

/**
 * Convert an array of string representing numbers (`["13.37", "42", ...]`)
 * to an array of numbers (`[13.37, 42, ...]`).
 * @param  {string[]} pArray An array of string representing numbers.
 * @return {number[]}        An array of numbers.
 */
export const strArrToNumberArr = (pArray) => {
  let lResult;

  if (!pArray) {
    lResult = [0.0, 0.0, 0.0];
  } else {
    let lTempArray;
    if (typeof pArray === typeof 'String') {
      lTempArray = pArray.split(' ');
    } else {
      lTempArray = pArray;
    }
    lResult = lTempArray.map((pCoord) => parseFloat(pCoord));
  }

  return lResult;
};

/**
 * Check if a string loosely includes another string
 *
 * @link https://stackoverflow.com/a/37511463/6503813
 * @param {String} str
 * @param {String} subStr
 *
 * @returns {boolean}
 */
export const strIncludesLoosely = (str, subStr) => {
  // Convert to lowercase, normalize and remove accents from both strings
  const newStr = str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const newSubStr = subStr
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return newStr.includes(newSubStr);
};

/**
 * @param {string} str a string with a file extension
 */
export const getFileExt = (str) => {
  return str.split('.').pop();
};

/**
 * Cross-browser fullscreen toggle for a given element
 *
 * @param {Element} DOMElement
 */
export const toggleFullScreen = (DOMElement) => {
  if (!document.mozFullScreen && !document.webkitFullScreen) {
    if (DOMElement.mozRequestFullScreen) {
      DOMElement.mozRequestFullScreen();
    } else {
      DOMElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else {
      document.webkitCancelFullScreen();
    }
  }
};