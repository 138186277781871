import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'utils/withRouter';
import { useTheme, withStyles, withTheme } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import {
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Tooltip
} from '@mui/material';
import { DeleteForever as DeleteForeverIcon, SyncDisabled as SyncDisabledIcon, Videocam as VideocamIcon } from '@mui/icons-material';
import { compose } from 'utils';
import { _t } from 'utils/i18n';
import { withMapManager } from 'MapManagerContext';
import { withAnnotations } from 'CopContext';
import { buildIconStyle, getDisplayTextForAnnotationType } from 'utils';
import { withNetworkManager } from 'NetworkManagerContext';
import { ClassicSpinner } from 'react-spinners-kit';

const styles = (theme) => ({
  unsyncedItem: {
    backgroundColor: theme.palette.grey[100],
    opacity: 0.7,
    paddingRight: '5.5rem'
  },
  secondaryAction: {
    display: 'flex',
    alignItems: 'center'
  },
  spinner : {
    color : theme.palette.primary.main
  }
});

const pad = (n, size) => {
  let s = String(n);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};

const formatDate = (pDate) => {
  const lNow = new Date();
  if (lNow.getFullYear() === pDate.getFullYear()) {
    if (lNow.getMonth() === pDate.getMonth()) {
      if (lNow.getDate() === pDate.getDate()) {
        return pad(pDate.getHours()) + ':' + pad(pDate.getMinutes());
      } else {
        return pad(pDate.getDate()) + '/' + pad(pDate.getMonth() + 1);
      }
    }
  }

  return pad(pDate.getDate()) + '/' + pad(pDate.getMonth() + 1) + '/' + pad(pDate.getFullYear());
};

const viewCamera = (annotation) => {
  const url = annotation.engine.Url;
  //this.setState({value: 'rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mp4'});
  if (window.cordova && window?.plugins?.streamingMedia) {
    // Just play a video
    window.plugins.streamingMedia.playVideo(url);
  }
};

class InformationSnack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetBlobURL : null,
      MIMEType: '',
      audioPlayerOpen: false,
      videoPlayerOpen: false,
      assetLoading: false
    }
  }

  getAction = (annotation) => {
    if (!annotation) {
      return null;
    }

    switch (annotation.type) {
      //case 'Sector':
      //  return _t('Edit');

      case 'Incident':
      case 'Photo':
      case 'GeoMedia':
      case 'Mean':
        return _t('More');

      case 'Camera':
        return _t('View');

      default:
      // console.info('unknown annotation type');
    }

    return null;
  };

  doAction = (annotation) => {
    if (!annotation) {
      return;
    }

    switch (annotation.type) {
      case 'Incident':
        this.props.history.push(`/incident/${annotation.Uuid}`);
        break;

        case 'IncidentMission':
          this.props.history.push(`/incidentmission/${annotation.Uuid}`);
          break;

        case 'Mean':
          this.props.history.push(`/mean/${annotation.Uuid}`);
          break;

      /*case 'Sector': {
        const updateSector = (pGeometry) => {
          const geometry = annotation.FeatureGeometry;
          geometry.Points = getPointsFromPolygon(pGeometry.coordinates);
          this.props.updateAnnotation(annotation);
        };

        this.props.MapManager.startEditTool(annotation.Uuid, updateSector);
        break;
      }*/

      case 'GeoMedia':
        if (annotation.AssetPath || (annotation.AssetPaths && annotation.AssetPaths.length > 0)) {
          const lAssetPath = annotation.AssetPath ? annotation.AssetPath : annotation.AssetPaths[0];
          this.setState({assetLoading: true})

          // Open audio in a dialog when on Mobile
          this.props.NetworkManager.getAsset(lAssetPath).then((assetBlob) => {
            if (window.cordova) {
              if (assetBlob.type.includes('audio')) {
                this.setState({
                  assetBlobURL: URL.createObjectURL(assetBlob),
                  MIMEType: assetBlob.type,
                  audioPlayerOpen: true})
              } else if ((assetBlob.type.includes('video'))) {
                this.setState({
                  assetBlobURL: URL.createObjectURL(assetBlob),
                  MIMEType: assetBlob.type,
                  videoPlayerOpen: true})
              } else {
                this.props.openAsset(lAssetPath);
              }
            } else {
                window.open(URL.createObjectURL(assetBlob))
            }
            this.setState({assetLoading: false})
          })
        }
        break;

      case 'Photo':
        if (annotation.AssetPath || (annotation.AssetPaths && annotation.AssetPaths.length > 0)) {
          const lAssetPath = annotation.AssetPath ? annotation.AssetPath : annotation.AssetPaths[0];

          this.props.openAsset(lAssetPath);
        }
        break;

      default:
        break;
    }
  };

  handleRequestClose = (pReason) => {
    this.setState({
      data: []
    });
  };

  handleDialogClose = () => {
    this.setState({
      iaDialogOpen: false,
      zsDialogOpen: false
    });
  }

  renderDialog(items) {
    const { classes, disabled, handleClose, online, open, removeAnnotation, user } = this.props;

    const listItems = [];
    const filteredItems = [];
    items.forEach((item) => {
      if (!filteredItems.find(it => it.annotation === item.annotation)) {
        filteredItems.push(item)
      }
    })
    for (const item of filteredItems) {
      const { annotation, message, icon, color, secondary } = item;

      const style = buildIconStyle(icon, color, 32);
      const action = this.getAction(annotation);

      listItems.push(
        <div key={annotation.Uuid}>
        <ListItem
          key={annotation.Uuid}
          disabled={disabled}
          onClick={() => this.doAction(annotation)}
          button={Boolean(action)}
          className={classnames({
            [classes.unsyncedItem]: !annotation.synced
          })}
        >
          {icon && (
            <ListItemIcon>
              <div style={style} />
            </ListItemIcon>
          )}
          {(annotation.type === 'Mean') && (
            <div>
              <ListItemText key={'text'} primary={annotation.MeanSubType} />
              {annotation.MeanType ? <ListItemText key={'type'} secondary={
                `Type : ${annotation.MeanType}`} /> : null}
              <ListItemText key={'category'} secondary={
                `Catégorie : ${annotation.Category}`} />
            </div>
            )}
          {annotation.type !== 'Mean' && (
            <ListItemText primary={message} secondary={secondary} />
          )}
          {(action || !annotation.synced) && (
            <ListItemSecondaryAction className={classes.secondaryAction}>
              {!annotation.synced && (
                <ListItemIcon>
                  <Tooltip title={_t('Unsynced element')} disableInteractive>
                    <SyncDisabledIcon />
                  </Tooltip>
                </ListItemIcon>
              )}
            </ListItemSecondaryAction>
          )}

          {/* A user can remove a surface he created (checked by username equality) */}
            {annotation.synced === true &&
              annotation.hasOwnProperty('LayerTag') &&
              annotation.LayerTag === 'Polygons' &&
              // If annotation has no emitter, check from ModifierName
              (annotation.hasOwnProperty('emitter')
                ? annotation.emitter === user
                : annotation.ModifierName === user) && (
          <ListItemSecondaryAction className={classes.secondaryAction}>
                <IconButton
                  edge="end"
                  aria-label="delete surface"
                  onClick={() => removeAnnotation(annotation)}
                  size="large">
                  <DeleteForeverIcon />
                </IconButton>
          </ListItemSecondaryAction>)}
          {/* view camera */}
          {annotation.synced === true && annotation.type === 'Camera' && (
          <ListItemSecondaryAction className={classes.secondaryAction}>
          <IconButton
            edge="end"
            aria-label="view video"
            onClick={() => viewCamera(annotation)}
            size="large">
                  <VideocamIcon />
                </IconButton>
          </ListItemSecondaryAction>)}
          {!annotation.synced && !online && <ListItemSecondaryAction />}
        </ListItem>
        </div>
      );
    }
    return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        <List dense={true}>{listItems}</List>
      </Dialog>
    );
  }

  getStringBetween(str, start, end) {
    const result = str.match(new RegExp(start + "(.*)" + end));

    return result[1]
  }

  handleOrderSecondary(comment) {
    const title = this.getStringBetween(comment, '<u><b>', '</b></u>')
    const messageValue = this.getStringBetween(comment, 'Message :', '')
    const order = `${title} : ${messageValue}`

    return order
  }

  render() {
    const { annotations, features, theme } = this.props;

    if (!features || features.length === 0) {
      return null;
    }

    const items = [];

    // TODO: move calculations outside of render
    // If there is at least a point feature, it gets all the attention
    const pointFeatures = features.filter((feature) => feature.geometry.type === 'Point');
    const readFeatures = pointFeatures.length > 0 ? pointFeatures : features;

    for (const feature of readFeatures) {
      const annotation = annotations[feature.featureId];
      if (annotation) {
        const comments = Object.values(annotations).filter(
          (a) => a.type === 'Comment' && a.Comment && a.CommentedUUID === annotation.Uuid
        );
        comments.sort((a, b) => new Date(b.CreationDate) - new Date(a.CreationDate));
        const lastComment = comments.length === 0 ? null : comments[0];
        const isOrder =  lastComment?.Comment?.includes('<u><b>');
        let orderComment = ''
        if(isOrder) {
          orderComment = this.handleOrderSecondary(lastComment.Comment);
        } 
        if (annotation) {
          let message = annotation.Name;
          if (!message) {
            message = getDisplayTextForAnnotationType(annotation.type);
          }

          const iconData = this.props.MapManager.getIconData(annotation);
          const icon = iconData ? iconData[0] : null;
          const color = iconData ? iconData[1] : null;

          items.push({
            annotation,
            message,
            icon,
            color,
            secondary: lastComment
              ? `${formatDate(new Date(lastComment.CreationDate))} - ${lastComment.emitter} : ${isOrder ? orderComment : lastComment.Comment}`
              : ''
          });
        }
      }
    }

    if (items.length === 0) {
      return null;
    }
    // If dialog is audio or video, once clicked, switch to dialog with media player.
    if ((this.state.audioPlayerOpen || this.state.videoPlayerOpen) || this.state.assetLoading) {
      return (
        <Dialog 
          open={(this.state.audioPlayerOpen || this.state.videoPlayerOpen) || this.state.assetLoading} 
          onClose={() => this.setState({audioPlayerOpen: false, videoPlayerOpen: false})} 
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          {this.state.assetLoading ? (
            <div style={{margin: 'auto', width: 'fit-content',height: '15vh'}}>
              <ClassicSpinner color={theme.palette.primary.main} loading />
            </div>
          ) : this.state.audioPlayerOpen ? (
            <audio controls style={{maxWidth: '80vw',maxHeight: '10vh'}} controlsList="nodownload noplaybackrate">
              <source src={this.state.assetBlobURL} type={this.state.MIMEType} />
            </audio>
          ): (
            <video controls controlsList="nodownload noplaybackrate">
              <source src={this.state.assetBlobURL} type={this.state.MIMEType}/>
            </video>
          )}
        </Dialog>
      )
    } else {
      return (
        <div style={{ zIndex: 125 }}>{this.renderDialog(items)}</div>
      )
    }
    
  }
}

InformationSnack.propTypes = {
  history: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  withStyles(styles),
  withTheme,
  withTranslation('common'),
  withMapManager,
  withNetworkManager,
  withAnnotations({})
)(InformationSnack);
