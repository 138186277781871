import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'utils/withRouter';
import Lightbox from 'react-image-lightbox';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import {Alert} from '@mui/material'
import { withStyles } from '@mui/styles';
import { Close as CloseIcon, CameraAlt } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import { withMapManager } from 'MapManagerContext';
import { withNetworkManager } from 'NetworkManagerContext';
import { withCop } from 'CopContext';
import { withAppConfig } from 'AppConfig';
import { compose } from 'utils';
import { _t } from 'utils/i18n';
import LocationButton from 'components/Map/LocationButton';
import LayerMenuButton from 'components/Map/LayerMenuButton';
import InformationSnack from 'components/Map/InformationSnack';
import TopLoader from 'components/TopLoader/TopLoader';
import GeocodingFab from 'components/Geocoding/GeocodingFab/GeocodingFab';
import 'VirtualGeoWeb/css/vgeo.css';
import { KeycloakManager, SettingsManager } from 'services';
import { withAnnotations } from 'CopContext';
import CompassZoomWidget from './Map/Compass';

const MissionIndicator = React.lazy(() => import('Plugins/TeamPlugin/MissionIndicator'));
const SupportClosureIndicators = React.lazy(() => import('Plugins/TeamPlugin/SupportClosureIndicators'));
const MapTalkingUserIndicator = React.lazy(() =>
  import('Plugins/PushToTalkPlugin/MapTalkingUserIndicator/MapTalkingUserIndicator')
);

const styles = {
  dialog: {
    maxWidth: 400,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  speedDial: {
    position: 'absolute',
    bottom: 64,
    right: 0,
    zIndex: '100'
  },
  snackbar: {
    zIndex: 1000,
    marginTop: 70,
    marginRight: 55
  },
  speedDialFab: {
    margin: 5
  },
  speedDialTooltip: {
    width: 130
  },
  speedDialActions: {
    paddingBottom: '34px !important' // default is 48
  },
  videoPlayer: {
    // Fill screen
    zIndex: 2000,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: '100vh',
    width: '100%',
    backgroundColor: 'black'
  },
  videoPlayerCloseIcon: {
    color: 'white',
    position: 'absolute',
    zIndex: 2001,
    top: 8,
    right: 8
  }
};

/**
 * Map component
 * @returns {React.ComponentClass}
 */
class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addDialogVisible: -1,
      pickedFeatures: null,
      informationOpen: false,
      // menuAnchor: null,
      mediaURL: '',
      speedDialOpen: false,
      snackbarOpen: false,
      snackbarType: 'info',
      snackbarMsg: 'Cliquez sur la carte pour ajouter un élément',
      lightboxOpen: false,
      videoPlayer: {
        open: false,
        MIMEType: ''
      },
      disableInformationSnack: false,
      talkingUserIndicatorOpen: false,
      disableSpeedial: false
    };

    this.clickX = 0;
    this.clickY = 0;
    this.toolMode = false;

    this.videoPlayerRef = React.createRef();

    this.onmousedown = (e) => {
      this.clickX = e.clientX;
      this.clickY = e.clientY;
    };

    this.onmouseup = (e) => {
      if (e.button === 0) {
        this.pickFeatures(e.clientX, e.clientY);
      }
    };

    this.pickFeatures = (x, y) => {
      if (!this.toolMode && Math.abs(this.clickX - x) < 1 && Math.abs(this.clickY - y) < 1) {
        const rect = props.MapManager.getMap().getTarget().getBoundingClientRect();
        const features = props.MapManager.getMap().pickFeatures({ x: x - rect.left, y: y - rect.top });
        this.onFeaturePicked(features);
        return features.length > 0;
      }
      return false;
    };

    this.ontouchstart = (e) => {
      if (e.touches.length === 1) {
        this.clickX = e.touches[0].clientX;
        this.clickY = e.touches[0].clientY;
      }
    };

    this.ontouchend = (e) => {
      if (e.touches.length === 0 && e.changedTouches.length === 1) {
        const t = e.changedTouches[0];
        if (this.pickFeatures(t.clientX, t.clientY)) {
          e.preventDefault();
        }
      }
    };

    this.preventContextMenu = (event) => {
      event.preventDefault();
    };

    this.onVGeoLogMessage = (log) => {
      const prefix = 'VirtualGeoWeb:';

      switch (log.code) {
        case 'Information':
          console.info(prefix, log.message);
          break;
        case 'Low Warning':
          console.warn(prefix, log.message);
          break;
        case 'High Warning':
          console.warn(prefix, log.message);
          break;
        case 'Error':
          console.error(prefix, log.message);
          break;
        default:
          console.log(log);
          break;
      }
    };

    this.setupEventListeners = () => {
      const map = props.MapManager.getMap();
      const canvas = map.getCanvas();

      // In dev mode handle VGeoWeb event logs
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        map.addEventListener('error', this.onVGeoLogMessage);
      }

      canvas.addEventListener('mousedown', this.onmousedown, false);
      canvas.addEventListener('touchstart', this.ontouchstart, false);
      canvas.addEventListener('mouseup', this.onmouseup, false);
      canvas.addEventListener('touchend', this.ontouchend);

      document.addEventListener('contextmenu', this.preventContextMenu);
      document.addEventListener('keydown', this.handleKeyDown);
    };
  }

  /**
   * Initialize the VirtualGeo map
   */
  componentDidMount() {
    if (!this.props.MapManager.getMap() || !this.props.MapManager.mapReady) {
      this.props.MapManager.createMap(this.setupEventListeners);
      this.keyEventAdded = false;
    } else {
      this.props.MapManager.getMap().setTarget('map');
      this.setupEventListeners();
    }

    // MCOP plugin specific logic if enabled
    if (window.cordova && window.MCOP && 'MCOPClient' in window) {
      window.MCOPClient.onFloorControlIdle(() => {
        console.info('EVENT INFO: onFloorControlIdle');
        this.setState({ talkingUserIndicatorOpen: false });
      });

      window.MCOPClient.onFloorControlTaken(() => {
        console.info('EVENT INFO: onFloorControlTaken');
        this.setState({ talkingUserIndicatorOpen: true });
      });
    }
  }

  // This is a temporary fix to override geoMedias style
  // Waiting for a bug to be fixed in Vgeo
  componentDidUpdate() {
    this.props.geoMedias.forEach(async (geoMedia, index) => {
      const lStyle = await this.props.MapManager.getMap().getStyle(geoMedia.Uuid)
      lStyle.icon[0].depthOffset = -10 - index
      this.props.MapManager.getMap().updateStyle(lStyle)
    }
    )
  }

  componentWillUnmount() {
    const { MapManager } = this.props;

    const map = MapManager.getMap();

    if (map) {
      const canvas = map.getCanvas();

      // Cancel subscriptions
      canvas.removeEventListener('mousedown', this.onmousedown, false);
      canvas.removeEventListener('mouseup', this.onmouseup, false);
      canvas.removeEventListener('touchstart', this.ontouchstart, false);
      canvas.removeEventListener('touchend', this.ontouchend, false);
      map.removeEventListener('error', this.onVGeoLogMessage);

      // Clear the VirtualGeo map data
      map.setTarget(null);
    }
    document.removeEventListener('contextmenu', this.preventContextMenu);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  /**
   * Called when features are picked
   */
  onFeaturePicked = (features) => {
    this.setState({ pickedFeatures: features, informationOpen: true });
    this.props.setWidgetStatus({
      open: true,
      callback: this.externallyCloseInformation
    });
  };

  onFinish = () => {
    this.setState({ addDialogVisible: -1, disableSpeedial: !this.state.disableSpeedial });
  }

  handleInformationClose = () => {
    this.setState({ informationOpen: false });
    this.props.setWidgetStatus({
      open: false,
      callback: null
    });
  };

  externallyCloseInformation = () => {
    this.setState({ informationOpen: false });
  };

  openMedia = (blobRef, MIMEType) => {
    const mediaType = MIMEType.substring(0, 5).toLowerCase();
    if (mediaType === 'image') {
      this.openLightbox(blobRef);
    } else if (mediaType === 'video') {
      this.openVideoPlayer(blobRef, MIMEType);
    } else {
      if (window.cordova) {
        // Phone behavior
        window.cordova.InAppBrowser.open(blobRef, '_blank', 'location=no');
      } else {
        // Browser behavior
        window.open(blobRef);
      }
      this.setState({ disableInformationSnack: false });
    }
  };

  openLightbox = (blobRef) => {
    this.setState({
      lightboxOpen: true,
      mediaURL: blobRef,
      informationOpen: false
    });

    this.props.setWidgetStatus({
      open: true,
      callback: this.externallyCloseMedia
    });
  };

  closeLightbox = () => {
    this.setState({
      lightboxOpen: false,
      mediaURL: '',
      disableInformationSnack: false
    });

    // Handles back button press
    this.props.setWidgetStatus({
      open: false,
      callback: null
    });
  };

  externallyCloseMedia = () => {
    this.setState({
      lightboxOpen: false,
      mediaURL: '',
      videoPlayer: {
        open: false,
        MIMEType: ''
      },
      disableInformationSnack: false
    });

    this.props.setNavigationVisible(true);
  };

  openVideoPlayer = (blobRef, MIMEType) => {
    this.setState({
      videoPlayer: {
        open: true,
        MIMEType
      },
      informationOpen: false,
      mediaURL: blobRef
    });

    this.props.setWidgetStatus({
      open: true,
      callback: this.externallyCloseMedia
    });

    this.props.setNavigationVisible(false);
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.externallyCloseMedia();
    }
  };

  handleOpenSpeedDial = () => this.setState({ speedDialOpen: true });

  handleCloseSpeedDial = () => this.setState({ speedDialOpen: false });

  handleOpenSnackbar = () => this.setState({ snackbarOpen: true });

  handleCloseSnackbar = () => this.setState({ snackbarOpen: false });

  addMapElement = (event) => {
    const { MapManager, setWidgetStatus, appConfig } = this.props;
    this.setState({ disableSpeedial: !this.state.disableSpeedial });

    // Might work with simply 'currentTarget' in the future ?...
    const value = parseInt(event.currentTarget.querySelector('button').value);

    this.handleCloseSpeedDial();
    setWidgetStatus({ open: false, callback: null });

    this.toolMode = true;

    const { geometryType, toolType, message } = appConfig.mapAdders[value];

    this.setState ({
      snackbarMsg: (_t(message))
    })

    this.handleOpenSnackbar();

    document.addEventListener('clickaway', () => this.handleCloseSnackbar());
    document.addEventListener('contextmenu', () => this.handleCloseSnackbar());

    const fn = (geometry, additionalData) => {
      this.toolMode = false;
      this.handleCloseSnackbar();
      this.setState({
        geometry,
        addDialogVisible: value,
        additionalData: additionalData,
      });

      document.removeEventListener('clickaway', () => this.handleCloseSnackbar())
      document.removeEventListener('contextmenu', () => this.handleCloseSnackbar());
    };

    // If the plugin has no geometry type, directly fire callback
    if (!geometryType) {
      fn();
      return;
    }

    if (toolType === 'measure') {
      MapManager.startMeasureTool(geometryType, fn);
    } else {
      MapManager.startDrawTool(geometryType, fn);
    }
  };

  openAsset = (assetPath) => {
    this.setState({ disableInformationSnack: true });

    this.props.NetworkManager.getAsset(assetPath)
      .then((pAssetBlob) => {
        const blobPath = window.URL.createObjectURL(pAssetBlob);

        this.openMedia(blobPath, pAssetBlob.type);
      })
      .catch((e) => {
        console.log('error', e);
      });
    // TODO: revoke object URL
  };

  handlePhotoReport = () => {
    this.props.history.push('/photo');
  };

  /**
   * Render the map container
   */
  render() {
    const {
      activeMission,
      classes,
      currentUserTeamInfo,
      MapManager,
      online,
      setWidgetStatus,
      supportClosureStatus,
      removeAnnotation,
      user,
      appConfig
    } = this.props;

    const {
      additionalData,
      addDialogVisible,
      disableInformationSnack,
      geometry,
      informationOpen,
      lightboxOpen,
      mediaURL,
      pickedFeatures,
      speedDialOpen,
      snackbarOpen,
      snackbarType,
      snackbarMsg,
      videoPlayer,
      talkingUserIndicatorOpen
    } = this.state;

    // Map actions from plugins
    const AddMenuItems = appConfig.mapAdders.map((el, index) => {
      const isTeamPlugin = el.hasOwnProperty('plugin') && el.plugin === 'team';
      const isTeamLeaderAction = isTeamPlugin && el.leaderOnly === true;

      // If this is a team plugin action and the user is not in a team, hide it
      if (!currentUserTeamInfo.isInTeam && isTeamPlugin) {
        return null;

        // If this user is in a team and the action is only visible to leaders but he isn't the leader
      } else if (currentUserTeamInfo.isInTeam && isTeamLeaderAction && !currentUserTeamInfo.isLeader) {
        return null;
      } else {
        // Prevent some actions to be started simultaneously (to prevent indicators overlapping and such)
        const isMissionAction = el.hasOwnProperty('id') && el.id === 'missionAction';
        const isSupportClosureAction = el.hasOwnProperty('id') && el.id === 'supportClosureAction';
        const isMissionActive = activeMission === true;
        const isSupportClosureActive = supportClosureStatus && supportClosureStatus !== 'INACTIVE';

        const isActionDisabled =
          (isMissionAction || isSupportClosureAction) && (isMissionActive || isSupportClosureActive);

        return (
            <SpeedDialAction
              key={el.title}
              tooltipTitle={_t(el.title)}
              tooltipOpen
              onClick={!isActionDisabled ? this.addMapElement : () => null}
              icon={el.hasOwnProperty('icon') ? <el.icon /> : undefined}
              FabProps={{
                value: index,
                disabled: isActionDisabled
              }}
              classes={{ staticTooltipLabel: classes.speedDialTooltip }}
            />
        );
      }
    });

    // Plugin dialogs
    const AddDialogs = appConfig.mapAdders.map((value, index) => {
      const AddDialog = value.dialog;
      return (
        <AddDialog
          key={value.title}
          open={addDialogVisible === index}
          geometry={geometry}
          additionalData={additionalData}
          onFinish={this.onFinish}
          forceOpen={(geometry1) => this.setState({ addDialogVisible: index, geometry: geometry1 })}
        />
      );
    });

    // Plugin Map Components
    const AddMapComponents = appConfig.mapComponents.map((value, index) => {
      const AddMapComponent = value.component;
      return (
        <AddMapComponent
          key={value.title}
          disable={value.online ? !online : null}
        />
      );
    });

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {MapManager.mapReady ? (
          <>
            {AddMapComponents}

            <GeocodingFab disabled={!online} />
            <LayerMenuButton setWidgetStatus={setWidgetStatus} />
            <CompassZoomWidget/>

            {(!SettingsManager?.map?.alwaysReadOnly && KeycloakManager.userRoles.has("UR_Cartography_RW")) &&
              <SpeedDial
                ariaLabel="Toggle actions"
                className={classes.speedDial}
                classes={{ actions: classes.speedDialActions }}
                icon={<SpeedDialIcon />}
                onClose={this.handleCloseSpeedDial}
                onOpen={this.handleOpenSpeedDial}
                open={speedDialOpen}
                ///
                FabProps={{
                  size: 'medium',
                  className: classes.speedDialFab,
                  // Disable when offline or when support closure is active
                  disabled: !online || supportClosureStatus === 'CONFIGURATION' || this.state.disableSpeedial
                }}
                transitionDuration={0} // disable transition
              >
                <SpeedDialAction
                  icon={<CameraAlt />}
                  tooltipTitle={_t('Report')}
                  tooltipOpen
                  classes={{ staticTooltipLabel: classes.speedDialTooltip }}
                  onClick={this.handlePhotoReport}
                />

                {AddMenuItems.map((item) => item)}
              </SpeedDial>
            }

            <LocationButton />

            <React.Suspense fallback={null}>
              <MissionIndicator />
              <SupportClosureIndicators />
              <MapTalkingUserIndicator open={talkingUserIndicatorOpen} />
            </React.Suspense>
          </>
        ) : null}

        <div
          id="map"
          style={{
            height: '100%',
            width: '100%',
            zIndex: 0,
            overflow: 'hidden'
          }}
        />

        {AddDialogs}

        <Snackbar
            open={snackbarOpen}
            onClose={this.handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            classes={{ root: classes.snackbar }}
            sx={{marginLeft: {xs: '0', lg: '80px'}}}
          >
            <Alert onClose={this.handleCloseSnackbar} severity={snackbarType}>
              {snackbarMsg}
            </Alert>
          </Snackbar>

        <InformationSnack
          user={user}
          features={pickedFeatures}
          open={informationOpen}
          handleClose={this.handleInformationClose}
          openAsset={this.openAsset}
          // Prevent user interaction when media is loading
          disabled={disableInformationSnack}
          removeAnnotation={removeAnnotation}
        />

        {/* Only shown when asset is downloading */}
        <TopLoader loading={disableInformationSnack && !lightboxOpen && !videoPlayer.open} />

        {lightboxOpen && (
          <Lightbox
            mainSrc={mediaURL}
            onCloseRequest={this.closeLightbox}
            reactModalStyle={{
              overlay: {
                zIndex: 1501
              }
            }}
          />
        )}
        {videoPlayer.open && (
          <div>
            <video
              controls
              className={classes.videoPlayer}
              ref={this.videoPlayerRef}
              name="geomedia-video"
              src={mediaURL}
              controlsList="nodownload"
            ></video>
            <IconButton
              className={classes.videoPlayerCloseIcon}
              onClick={this.externallyCloseMedia}
              size="large">
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  }
}

Map.propTypes = {
  team: PropTypes.object,
  teammates: PropTypes.array,
  currentUserTeamInfo: PropTypes.object.isRequired,
  activeMission: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
  setWidgetStatus: PropTypes.func.isRequired,
  updateAnnotation: PropTypes.func.isRequired,
  supportClosureStatus: PropTypes.string,
  MapManager: PropTypes.object.isRequired,
  NetworkManager: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withNetworkManager,
  withMapManager,
  withCop,
  withAppConfig, 
  withStyles(styles),
  withAnnotations({
  mapAnnotationsToProps: (annotations) => ({
    geoMedias: Object.values(annotations).filter(ann => ann.type === 'GeoMedia')
  })
}))(Map);
