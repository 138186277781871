import { StyleFactory } from 'services';

const PluginUtils = {
  appConfig: null,

  registerPlugin(pluginData, registerSelfStyledObject) {
    if (pluginData.route) {
      this.appConfig.topLevelRoutes.push({
        path: pluginData.route.path,
        component: pluginData.route.component
      });
    }

    if (pluginData.drawer) {
      this.appConfig.mainDrawerItems.push(pluginData.drawer);
    }

    // Handle plugins with multiple map adders
    if (pluginData.hasOwnProperty('mapAdders') && pluginData.mapAdders.length) {
      pluginData.mapAdders.forEach((mapAdder) => {
        this.appConfig.mapAdders.push(mapAdder);
      });
    } else if (pluginData.mapAdder) {
      this.appConfig.mapAdders.push(pluginData.mapAdder);
    }

    if (pluginData.manager) {
      this.appConfig.managers.push(pluginData.manager);
    }

    if (pluginData.settingItem) {
      this.appConfig.settingItems.push(pluginData.settingItem);
    }

    if (pluginData.mainNavigationRoute) {
      this.appConfig.mainNavigationRoutes.push(pluginData.mainNavigationRoute);
    }

    if (pluginData.mapComponents) {
      this.appConfig.mapComponents.push(pluginData.mapComponents);
    }
  }
};

export default PluginUtils;
