import { v4 as uuidv4 } from 'uuid';
import { KeycloakManager, SettingsManager } from 'services';

const messages = {
  403: 'Forbidden action',
  409: 'Conflict',
  default: 'Default server error'
};

/**
 * @param {Object} params
 * @param {number} params.status
 * @param {*} params.translator
 *
 * @returns {string}
 */
export const getServerErrorMessage = ({ status, translator }) => {
  if (messages.hasOwnProperty(status)) {
    return translator(messages[status]);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      `The server sent the following error code : ${status}, but there is no message to display for this status. Please consider adding one in 'src/CopContext/utils/index'`
    );
  }
  return translator(messages.default);
};

/**
 * @param {Object} pIncident
 *
 * @returns {boolean}
 */
export const isIncidentInProgress = (pIncident) => pIncident.State !== 3;

/**
 *
 * @param {Object} incident
 * @param {Object | null} team
 *
 * @returns {boolean}
 */
export const isIncidentMine = (incident, team) => {
  const mine =
    (team &&
      incident &&
      incident.Owners &&
      incident.Owners.find(function (el) {
        return el.Uuid === team.Uuid;
      })) ||
    // old
    (team && team.IncidentUuids && team.IncidentUuids.indexOf(incident.Uuid) > -1);
  const done = incident.State === 3 || incident.State === 5;
  return mine && !done;
};

/**
 * Update the user information on team from the annotations
 * @param {*} annotations 
 */
export const updateUserTeamInformation = (annotations) => {
    // Update team and userinformation
    const { sub: currentUserId } = KeycloakManager.getUser();

    // Flatten team array
    const allTeams = Object.values(annotations['Team'] || {}).map( a => a.annotation );
    // Keep map for teammates
    const allTeammates = annotations['Teammate'] || {};

    // Now fin the user team
    const team = allTeams.find( (t) => {
      if (t.Leader === currentUserId) {
        return true;
      } else {
        return t.TeammatesUuids && t.TeammatesUuids.findIndex( (tmId) => { 
          const tm = allTeammates[tmId];
          return tm ? tm.annotation.ActorID === currentUserId : false;
        }) >= 0;
      }
    });

    // Now find teammates for the user team if any
    const teammates = team ? Object.values(allTeammates).reduce( (prevValue,a) => {
      const tm = a.annotation;
      if (team.TeammatesUuids && team.TeammatesUuids.indexOf(tm.Uuid) >= 0) {
        prevValue.push(tm);
      }
      return prevValue;
    }, []) : null;

    // Return user team information
    return {
      team,
      teammates,
      currentUserTeamInfo: {
        isLeader: team ? team.Leader === currentUserId : false,
        isInTeam: team != null
      }
    }
};

/**
 * @param {Array<Object>} queue
 *
 * @returns {number}
 */
export const countActions = (queue) =>
  queue.filter((item) => !item.annotation || item.annotation.type !== 'TableRow').length;

/**
 * Set default values for an annotation
 *
 * @param {Object} pAnnotation
 * @param {boolean} pIsCreation
 *
 * @returns {Object}
 */
export const setAnnotationDefaults = (pAnnotation, pIsCreation) => {
  const lIsoDate = new Date().toISOString();

  const newAnnotation = {
    ...pAnnotation,
    ModifierName: KeycloakManager.getUser().preferred_username,
    UpdateDate: lIsoDate,
    ModificationDate: lIsoDate,
    Timestamp: lIsoDate
  };

  // Remove too old for team
  if (newAnnotation.type === 'Team') {
    delete newAnnotation.TooOld;
  }
  // Remove synced (only local)
  delete newAnnotation.synced;

  if (pIsCreation) {
    newAnnotation.Uuid = pAnnotation.Uuid || uuidv4();
    newAnnotation.CreationDate = lIsoDate;
  }

  newAnnotation.emitter = KeycloakManager.getUser().preferred_username;

  return newAnnotation;
};

/**
 * Split an array into chunks
 * @param {*[]} arr
 * @param {number} len
 *
 * @returns {*[]}
 */
export const splitArrayIntoChunksOfLen = (arr, len) => {
  const chunks = [];
  let i = 0;

  while (i < arr.length) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
};

/**
 * Flatten an object with entities
 *
 * @param {Object} entities
 */
export const flatten = (entities) =>
  Object.values(entities).reduce((flatEntities, entity) => ({ ...flatEntities, ...entity }), {});

/**
 * Helper function to filter annotations based on this type
 */
export const filterAnnotations = ({
  type,
  shouldBeVisible,
  annotations,
  unsyncedAnnotations,
  team,
  teammate,
  props
}) => {
  const computedType = typeof type === 'function' ? type(props) : type;
  const hasAnnotationsOfType = annotations.hasOwnProperty(computedType);
  const hasVisibilityFilter = typeof shouldBeVisible === 'function';

  return (computedType
    ? hasAnnotationsOfType
      ? Object.values({
          ...annotations[computedType],
          ...unsyncedAnnotations[computedType]
        })
      : []
    : Object.values({
        ...flatten(annotations),
        ...flatten(unsyncedAnnotations)
      })
  )
    .filter(
      (annotation) =>
        !hasVisibilityFilter || shouldBeVisible({ annotation: annotation.annotation, team, teammate, props })
    )
    .map((annotation) => annotation.annotation)
    .reduce(
      (annotationsOfType, annotation) => {
        annotationsOfType[annotation.Uuid] = annotation;
        return annotationsOfType;
      },
      {}
    );
};

/**
 * Helper function to get matching annotations
 *
 * @param {*} types
 * @param {*} shouldBeVisible
 * @param {Object} annotations
 * @param {Object} team
 * @param {*} props
 */
export const getMatchingAnnotations = (
  types,
  shouldBeVisible,
  annotations,
  unsyncedAnnotations,
  team,
  teammate,
  props
) => {
  if (types) {
    return (Array.isArray(types) ? types : [types]).reduce((matchingAnnotations, annotationType) => {
      return {
        ...matchingAnnotations,
        ...filterAnnotations({
          type: annotationType,
          shouldBeVisible,
          annotations,
          unsyncedAnnotations,
          team,
          teammate,
          props
        })
      };
    }, {});
  } else {
    return filterAnnotations({
      shouldBeVisible,
      annotations,
      unsyncedAnnotations,
      team,
      teammate,
      props
    });
  }
};
