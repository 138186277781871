import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getAnnotationVersion, strArrToNumberArr, setColorOpacity, cleanupFileUrl } from 'utils';
import { GeoLocationManager, KeycloakManager, SettingsManager, StyleFactory } from 'services';
import { StyleHelper } from 'services/StyleFactory';
import { withAnnotations } from 'CopContext';
import { _t } from 'utils/i18n';
import VirtualGeo from 'VirtualGeoWeb/js/VirtualGeoWeb';

const MapManagerContext = React.createContext({
  createMap: (pFn) => {},
  getMap: () => {},
  getLayers: () => {},
  jumpTo: () => {},
  jumpToPoint: () => {},
  jumpToBound: () => {},
  startDrawTool: () => {},
  startMeasureTool: () => {},
  startEditionTool: () => {},
  getIconData: () => {},
  editStyle: () => {},
  dispose: () => {},
  toggleUse3D: () => {},
  use3D: true,
  mapReady: false
});

// For devtools
MapManagerContext.displayName = 'MapManagerContext';

const { Provider, Consumer } = MapManagerContext;

/**
 * Factorized code to add or update an object given its style, geometry and layer id.
 *
 * @param {Object} pStyle      The object style. Updated if it already exists.
 * @param {Object} pGeometry   The object's geometry (GeoJSON format). The `featureId` that will be used is the same as the style's name.
 * @param {string} pLayerId    The layer id. Layer will be created if needed.
 * @param {Object} pAnnotation The annotation
 * @param {Object} pMap        The map to add features to.
 */
function addMapObject(pStyle, pGeometry, pAnnotation, pMapManagerContext) {
  const pMap = pMapManagerContext.map;

  // Get the layer id
  let layerId = pAnnotation.type;
  if (pAnnotation.LayerName) {
    layerId = pAnnotation.LayerName;
  }
  if (pAnnotation.LayerTag) {
    layerId = pAnnotation.LayerTag;
  }
  const layerIdFromTag = pMapManagerContext.tag2layerId[layerId];
  if (layerIdFromTag) {
    layerId = layerIdFromTag;
  }

  // Create feature style if necessary
  if (!pMap.getLayer(layerId)) {
    const data = { id: layerId };
    if (layerId === 'Incident' || layerId === 'ZsIdAlert') {
      data.displayRank = 100.0;
    }
    pMap.addFeatureLayer(data);
  }

  // Add / update style
  const lStyles = pMap.getStyles();

  if (lStyles.find((pOtherStyle) => pOtherStyle.name === pStyle.name)) {
    pMap.removeStyle(pStyle.name);
  }

  const properties = { text: pAnnotation?.Name };

  // Doesn't seem necessary ??
  if (properties.text === undefined) {
    console.warn("Error: property 'Name' missing in annotation.");
    properties.text = '';
  }

  if (pStyle.properties) {
    for (const [lKey, lValue] of Object.entries(pStyle.properties)) {
      const lPropKey = `${lKey.slice(0, 1).toLowerCase()}${lKey.slice(1)}`;
      properties[lPropKey] = lValue;
    }
  }

  pMap.addStyle(pStyle);

  // Create feature
  const lFeatures = pMap.getFeatures({ layerId });
  const lFeature = {
    featureId: pStyle.name,
    layerId,
    geometry: pGeometry,
    styleName: pStyle.name,
    properties
  };

  // Add / update feature
  if (lFeatures.find((pOtherFeature) => pOtherFeature.featureId === pStyle.name)) {
    pMap.removeFeature(lFeature);
  }
  if (lFeature.geometry === undefined) {
    return null;
  }
  if (
    lFeature.geometry.type === 'Point' &&
    (isNaN(lFeature.geometry.coordinates[0]) ||
      isNaN(lFeature.geometry.coordinates[1]) ||
      isNaN(lFeature.geometry.coordinates[2]))
  ) {
    return null;
  }
  pMap.addFeature(lFeature);

  return { style: pStyle, feature: lFeature };
}

/**
 * Transform a VrGIS feature geometry data to GeoJSon.
 * @param  {Object} featureGeometry The VrGIS feature geometry data.
 * @return {Object} 				The GeoJSon data parsed data.
 */
function VrToGeoJSon(featureGeometry) {
  if (!featureGeometry) {
    return {};
  }

  let result;
  if (featureGeometry.type === 'PolygonGeometry') {
    let lPoints = featureGeometry.Points;

    // Make sure the polygon is closed.
    if (lPoints[0].toString() !== lPoints.slice(-1)[0].toString()) {
      lPoints.push(lPoints[0]);
    }

    lPoints = lPoints.map((pPoint) => strArrToNumberArr(pPoint.slice(0, 2)));

    result = {
      type: 'Polygon',
      coordinates: [lPoints]
    };
  } else if (featureGeometry.type === 'PointGeometry') {
    const positionArray = strArrToNumberArr(featureGeometry.Point)
    result = {
      type: 'Point',
      coordinates: SettingsManager.map.mode === '2D' ? [...positionArray.slice(0,2), 0] : positionArray
    };
  } else if (featureGeometry.type === 'CurveGeometry') {
    let lPoints = featureGeometry.Points;
    lPoints = lPoints.map((pPoint) => strArrToNumberArr(pPoint.slice(0, 2)));
    result = {
      type: 'LineString',
      coordinates: lPoints
    };
  } else if (featureGeometry.type === 'CircleGeometry') {
    const lPoint = featureGeometry.Point;
    result = {
      type: 'Circle',
      point: strArrToNumberArr(lPoint),
      radius: featureGeometry.Radius
    };
  } else if (featureGeometry.type === 'SphereGeometry') {
    result = {
      type: 'Sphere',
      Radius: parseFloat(featureGeometry.Radius),
      VerticalAperture: parseFloat(featureGeometry.VerticalAperture),
      HorizontalAperture: parseFloat(featureGeometry.HorizontalAperture),
      Elevation: parseFloat(featureGeometry.Elevation),
      Azimuth: parseFloat(featureGeometry.Azimuth),
      Point: strArrToNumberArr(featureGeometry.Point)
    };
  }
  // Try to add other more exotic Vr*Geometry type by using the default VGeoWeb deserializer
  else if (featureGeometry.type.indexOf("Geometry") > 0) {
    result = {
      ...featureGeometry,
      // Replace type name "VrTypeNameGeometry" to the generic one "TypeName"
      type: featureGeometry.type.replaceAll(/Geometry$/g, ""),
      // FIXME : VGEO : Remove interpolation that can crash with some geometry like plumes
      Interpolation: undefined
    };
    if (result.Size) {
      result.Size = strArrToNumberArr(result.Size);
    }
  }

  return result;
}

/**
 * Deserialize a style. Selects a type and calls the according function.
 * @param  {Object} pStyle The style data.
 * @return {Object}        The parsed style.
 */
function deserializeStyle(pStyle) {
  const lResult = {};

  switch (pStyle.type) {
    case 'VrIconStyle':
      lResult.icon = StyleHelper.buildIconStyle(pStyle);
      break;
    case 'VrCurveStyle':
    case 'VrLineStyle':
      lResult.line = StyleHelper.buildLineStyle(pStyle);
      break;
    case 'VrSurfaceStyle':
      lResult.surface = StyleHelper.buildSurfaceStyle(pStyle);
      break;
    case 'VrTextStyle':
      lResult.text = StyleHelper.buildTextStyle(pStyle);
      break;
    case 'VrComplexCurveStyle':
      lResult.complex = StyleHelper.buildComplexStyle(pStyle);
      break;
    default:
      break;
  }

  return lResult;
}

/**
 * Build a complete style.
 * @param  {Object} pStyle The style data
 * @return {Object}        The parsed style.
 */
function buildStyle(pStyle) {
  let lFinalStyle = {};

  if (pStyle.type === 'VrCompositeStyle') {
    pStyle.Styles.forEach((pIncludedStyle) => {
      lFinalStyle = Object.assign(lFinalStyle, deserializeStyle(pIncludedStyle));
    });
  } else {
    lFinalStyle = deserializeStyle(pStyle);
  }

  lFinalStyle.name = pStyle.name;

  return lFinalStyle;
}

/**
 * Find the style data for an annotation, depending on its contents.
 * @param  {Object} pAnnotation The annotation.
 * @return {Object}             The annotation style.
 */
function getStyle(pAnnotation) {
  let lStyle = pAnnotation.FeatureElement ? pAnnotation.FeatureElement.Style : null;
  if (lStyle && lStyle.type !== 'NULL') {
    return lStyle;
  }

  if (pAnnotation.Styles) {
    if (pAnnotation.Styles.Maps && pAnnotation.Styles.Maps.type !== 'NULL') {
      return pAnnotation.Styles.Maps;
    }

    if (pAnnotation.Styles['3D'] && pAnnotation.Styles['3D'].type !== 'NULL') {
      return pAnnotation.Styles['3D'];
    }

    for (let lIndex = 0; lIndex < pAnnotation.Styles.length; ++lIndex) {
      lStyle = pAnnotation.Styles[lIndex];
      if (lStyle && lStyle.type !== 'NULL') {
        return lStyle;
      }
    }
  }

  return null;
}

/**
 * Add a self styled object to the map.
 * @param {Object} annotation The annotation to add.
 * @param {Object} map        The map.
 */
function addSelfStyledObject(pAnnotation, pMapManagerContext) {
  const lStyle = StyleFactory.getStyle(pAnnotation);

  if (lStyle === undefined) {
    console.error('Unregistered style');
    return null;
  }

  // Get the geometry from the FeatureGeometry property
  // HACK: for backward compatibility with Photo, also use the FeatureElement.Geometry
  const lGeometry = pAnnotation.FeatureGeometry
    ? VrToGeoJSon(pAnnotation.FeatureGeometry)
    : VrToGeoJSon(pAnnotation.FeatureElement.Geometry);

  return addMapObject(lStyle, lGeometry, pAnnotation, pMapManagerContext);
}

/**
 * Add a geo annotation (not self-styled) to the map. This uses the annotation's style.
 * @param  {Object} annotation The annotation.
 * @param  {Object} map        The map.
 * @return {Object} 		   An object containing the style and the feature created (useful for udpates).
 */
function addGeoAnnotation(pAnnotation, pMapManagerContext) {
  if (!pAnnotation.FeatureElement.Geometry) {
    // This is not a geo object
    return null;
  }

  const lAnnotationStyle = getStyle(pAnnotation);

  if (!lAnnotationStyle) {
    console.error('No style found for annotation', pAnnotation);
    return null;
  }

  const lStyle = buildStyle(lAnnotationStyle);
  lStyle.name = pAnnotation.Uuid || pAnnotation.path;

  const lGeometry = VrToGeoJSon(pAnnotation.FeatureElement.Geometry);
  if (lGeometry === undefined) {
    return null;
  }
  return addMapObject(lStyle, lGeometry, pAnnotation, pMapManagerContext);
}

/**
 * Jump to a polygon geometry (its center of mass)
 * @param  {Array} 	pGeometry 	The polygon coordinates.
 * @param  {Object} pMap        The map.
 */
function getPolygonCenter(pGeometry) {
  const lPoint = [0.0, 0.0];

  pGeometry.forEach((pPoint) => {
    lPoint[0] += pPoint[0];
    lPoint[1] += pPoint[1];
  });
  lPoint[0] /= pGeometry.length;
  lPoint[1] /= pGeometry.length;

  return lPoint;
}

class MapManagerProvider extends Component {
  constructor(props) {
    super(props);

    /**
     * The VGeo map.
     * @type {Object}
     */
    this.map = null;

    this.camera = SettingsManager.map.defaultCamera;

    this.tag2layerId = {};

    this.annotationsData = {};

    this.geolinks = {};

    GeoLocationManager.getLocationCb((latitude, longitude) => {
      if (!this.cameraFromJumpRequest) {
        this.camera = {
          lat: latitude,
          lon: longitude,
          range: 20000
        };
        if (this.state.mapReady) {
          this.map.setCenterAdvanced(this.camera, true);
        }
      }
    });

    this.myPosCreated = false;
    this.geoLocationWatcher = -1;

    /**
     * Create the map
     */
    this.createMap = (fn) => {
      // eslint-disable-next-line camelcase
      window.ACOSE_Map = {};

      let mode = '3D';
      let projection = 'WGS84';
      if (SettingsManager.map && SettingsManager.map.mode) {
        mode = SettingsManager.map.mode;
      }
      if (SettingsManager.map && SettingsManager.map.projection) {
        projection = SettingsManager.map.projection;
      }

      /**
       * The VirtualGeo map
       */
      this.map = new VirtualGeo.Map({
        target: 'map',
        // licenseKey: process.env.REACT_APP_VIRTUALGEO_LICENCE_KEY,
        licenseKey: 'clH9umJPWlg6u4cjjaqrc9QXBdxDLp6AiIvmRC4iN3GsXo4HXCOTvbajrLzxcvktMrugK8TVz5drKU0shM37JiP',
        maxMemory: 256,
        autoResize: true,
        //debug: true,
        fallback: this.state.use3D ? 'allow' : 'force',
        enginePath: `${process.env.PUBLIC_URL}/`,
        useSky: false,
        mode,
        projection,
        whenLoaded: (map) => {
          map.setViewConfiguration({ rasterError: 1.2 });
          map.setCenterAdvanced(this.camera, true);

          this.map = map;
          this.onMapReady();

          window['VGEO_MAP'] = map;

          fn();
        }
      });
    };

    /**
     * Get the VGeo map.
     * @return {Object} The map.
     */
    this.getMap = () => {
      return this.map;
    };

    /**
     * Call to change if map use 3D version (WebGL + WebAssembly) or not
     * Need to recreate the map after this call (done by Map component)
     */
    this.toggleUse3D = () => {
      this.dispose();
      this.setState((state) => {
        localStorage.setItem('map.use3D', !state.use3D);
        return {
          use3D: !state.use3D
        };
      });
    };

    /**
     * Function triggered when the map is ready.
     * Draw annotations and store them locally.
     */
    this.onMapReady = () => {
      if (SettingsManager.map.scene) {
        // WORKAROUND: The .vrgis path resolves differently between the cordova and web versions
        let project = SettingsManager.map.scene;

        if (window.cordova) {
          project = project.replace(/^\/+/g, '');
        }

        this.map.loadProject(project);
      }
      if (SettingsManager.map.backgrounds) {
        SettingsManager.map.backgrounds.forEach((pLayer) => {
          this.map.addImageryLayer(pLayer);
          this.map.setLayerVisible(pLayer);
        });
      }
      if (SettingsManager.map.elevations) {
        SettingsManager.map.elevations.forEach((pLayer) => {
          this.map.addElevationLayer(pLayer);
          this.map.setLayerVisible(pLayer);
        });
      }
      if (SettingsManager.map.overlays) {
        SettingsManager.map.overlays.forEach((pLayer) => {
          if (pLayer.tags) {
            pLayer.tags.forEach((tag) => (this.tag2layerId[tag] = pLayer.id));
          }
          this.map.addLayer(pLayer);
          this.map.setLayerVisible(pLayer);
        });
      }

      this.myPosCreated = false;

      //this.map.setMeasureConfiguration({textSize:20,areaInHa: true,areaFormat: "Aire : %area%\nPérimètre : %perimeter%"})
      this.map.setMeasureConfiguration({
        textSize: 20,
        areaInHa: true,
        areaFormat: `${_t('Area')} : %area%\n${_t('Perimeter')} : %perimeter%`
      });

      this.map.addStyle({
        name: 'GEOLINK_STYLE',
        line: {
          width: 2.0,
          color: 'rgba(0,0,0,1)',
          opacity: 1.0
        }
      });
      this.map.addFeatureLayer({ id: 'GEOLINKS', title: 'Lignes' });

      Object.values(this.props.annotations).forEach(this.addAnnotation);

      this.setState({ mapReady: true });

      GeoLocationManager.getLocationCb(this.renderMyPosition);
      this.geoLocationWatcher = GeoLocationManager.watchLocation(this.renderMyPosition);
    };

    /**
     * Get the  layers
     */
    this.getLayers = () => {
      if (this.map) {
        return this.map.getLayers();
      } else {
        return [];
      }
    };

    this.dispose = (clearAnnotations = false) => {
      if (this.map) {
        this.state.mapReady = false;
        this.map.dispose();
        this.map = null;
        GeoLocationManager.clearWatch(this.geoLocationWatcher);
      }
    };

    /**
     * Move the camera to an annotation.
     * @param  {Object} annotation The annotation to jump to.
     * @param {Boolean} disableAnimation Disable the animation to center the map instantly on the POI.
     */
    this.jumpTo = (annotation, disableAnimation = false) => {
      const getGeometry = (pAnnotation) => {
        let lGeometry;
        if (pAnnotation.FeatureGeometry) {
          lGeometry = pAnnotation.FeatureGeometry;
        } else {
          lGeometry = pAnnotation.FeatureElement.Geometry;
        }

        return lGeometry;
      };

      const lGeometry = getGeometry(annotation);

      let targetPoint = [0.0, 0.0];
      if (lGeometry.Point) {
        targetPoint = lGeometry.Point;
      } else if (lGeometry.Surface) {
        targetPoint = getPolygonCenter(lGeometry.Surface);
      } else if (lGeometry.Points) {
        targetPoint = getPolygonCenter(lGeometry.Points.map((point) => [parseFloat(point[0]), parseFloat(point[1])]));
      } else {
        console.error('Geometry type not handled');
      }

      if (!this.state.mapReady) {
        this.cameraFromJumpRequest = true;
        this.camera = {
          lat: targetPoint[1],
          lon: targetPoint[0],
          range: 2000
        };
      } else {
        this.map.setCenterAdvanced(
          {
            lat: targetPoint[1],
            lon: targetPoint[0],
            range: 2000
          },
          disableAnimation
        );
      }
    };

    /**
     * Jump to a point geometry.
     * @param  {Number} 	pLat 				The point latitude
     * @param  {Number} 	pLong 				The point longitude
     * @param  {Boolean} 	pDisableAnimation   Disable animation to go to the position
     */
    this.jumpToPoint = (pLat, pLong, pDisableAnimation = false, pRange = 2000) => {
      this.map.setCenterAdvanced({ lat: pLat, lon: pLong, range: pRange }, pDisableAnimation);
    };

    this.editStyle = (annotationId, callback) => {
      if (this.annotationsData[annotationId] && this.annotationsData[annotationId].style) {
        const newStyle = callback(this.annotationsData[annotationId].style);
        this.map.updateStyle(newStyle);
        this.annotationsData[annotationId].style = newStyle;
      }
    };

    /**
     * Jump to a bound
     * @param  {Object} 	pFirstCorner 		The first corner coordinates.
     * @param  {Object} 	pSecondCorner 		The second corner coordinates.
     * @param  {Boolean} 	pDisableAnimation   Disable animation to go to the position
     */
    this.jumpToBound = (pFirstCorner, pSecondCorner, pDisableAnimation = false) => {
      this.map.zoomToBound(pFirstCorner, pSecondCorner, pDisableAnimation);
    };

    this.startDrawTool = (pType, pFunc) => {
      if (!this.state.mapReady) {
        return;
      }

      // First time we call this guy
      if (!this.map.getLayer('FeatureToolLayer')) {
        this.map.addFeatureLayer({ id: 'FeatureToolLayer' });
        this.map.addEventListener('creationfinished', this.drawToolHook);
      }

      this.featureData = { layerId: 'FeatureToolLayer', featureId: uuidv4() };
      this.callbackFunc = pFunc;
      this.removeFeature = true;

      this.map.startCreationTool({ ...this.featureData, type: pType });
    };

    this.startMeasureTool = (pType, pFunc) => {
      if (!this.state.mapReady) {
        return;
      }

      if (!this.map.getLayer('FeatureMeasureLayer')) {
        this.map.addFeatureLayer({ id: 'FeatureMeasureLayer' });
        this.map.addEventListener('measurefinished', this.measureToolHook);
      }

      this.callbackFunc = pFunc;

      this.map.startMeasureTool({ type: pType });
    };

    this.startEditTool = (pAnnotationId, pFunc) => {
      if (!this.state.mapReady) {
        return;
      }

      const lAnnotation = this.annotationsData[pAnnotationId];
      if (!lAnnotation) {
        return;
      }

      const lFeature = { ...lAnnotation.feature };

      this.map.addEventListener('editionfinished', this.drawToolHook);
      this.callbackFunc = pFunc;
      this.featureData = lFeature;
      this.removeFeature = false;

      this.map.startEditionTool(lFeature);
    };

    /**
     * Get the Icon Data of an annotation (should have a VrIconStyle).
     * @param  {Object} annotation The annotation.
     * @return {[]}            The icon Data (URL and Color) if it exists.
     */
    this.getIconData = (pAnnotation) => {
      let lStyle = getStyle(pAnnotation);
      if (!lStyle) {
        lStyle = StyleFactory.getStyle(pAnnotation);
      } else {
        lStyle = buildStyle(lStyle);
      }

      let lResult = null;

      if (lStyle && lStyle.icon) {
        // get rgb color from style and change it to rgba to apply mask and display color on listItem
        const lColor = setColorOpacity(lStyle.icon.color, 1.0);
        lResult = [lStyle.icon.iconURL, lColor];
      }

      return lResult;
    };

    this.state = {
      createMap: this.createMap,
      getMap: this.getMap,
      getLayers: this.getLayers,
      registerSelfStyledObject: this.registerSelfStyledObject,
      jumpTo: this.jumpTo,
      jumpToPoint: this.jumpToPoint,
      jumpToBound: this.jumpToBound,
      startDrawTool: this.startDrawTool,
      startMeasureTool: this.startMeasureTool,
      startEditionTool: this.startEditionTool,
      getIconData: this.getIconData,
      editStyle: this.editStyle,
      onMapReady: this.onMapReady,
      dispose: this.dispose,
      toggleUse3D: this.toggleUse3D,
      addAnnotation: this.addAnnotation,
      // If not specified, use 3D map
      use3D: SettingsManager?.map?.use3D === undefined ? true : SettingsManager.map.use3D,
      mapReady: false
    };
  }

  saveCamera(pCenter, pRange) {
    this.camera = {
      lat: pCenter.lat,
      lon: pCenter.lon,
      range: pRange
    };
  }

  renderMyPosition = (pLatitude, pLongitude, pAccuracy) => {
    if (this.state.mapReady) {
      this.myPosition = [pLongitude, pLatitude];

      if (!this.myPosCreated) {
        this.map.addFeatureLayer({
          id: 'GPS_Position_FeatureLayer',
          title: _t('My Position')
        });

        this.map.addStyle({
          name: 'GPS_Position_InnerStyle',
          icon: {
            iconURL: cleanupFileUrl('/img/my_pos.png'),
            size: [24, 24],
            offset: [0, -12]
          }
        });

        this.map.addFeature({
          layerId: 'GPS_Position_FeatureLayer',
          featureId: 'GPS_Position_Inner',
          styleName: 'GPS_Position_InnerStyle',

          geometry: {
            type: 'Point',
            coordinates: this.myPosition
          }
        });

        this.map.addStyle({
          name: 'GPS_Position_OuterStyle',
          surface: {
            color: 'rgba(63, 141, 248, 0.5)'
          }
        });

        this.map.addFeature({
          layerId: 'GPS_Position_FeatureLayer',
          featureId: 'GPS_Position_Outer',
          styleName: 'GPS_Position_OuterStyle',

          geometry: {
            type: 'Circle',
            radius: pAccuracy,
            point: this.myPosition
          }
        });

        this.myPosCreated = true;
      } else {
        this.map.updateFeature({
          layerId: 'GPS_Position_FeatureLayer',
          featureId: 'GPS_Position_Outer',
          styleName: 'GPS_Position_OuterStyle',

          geometry: {
            type: 'Circle',
            radius: pAccuracy,
            point: this.myPosition
          }
        });

        this.map.updateFeature({
          layerId: 'GPS_Position_FeatureLayer',
          featureId: 'GPS_Position_Inner',
          styleName: 'GPS_Position_InnerStyle',

          geometry: {
            type: 'Point',
            coordinates: this.myPosition
          }
        });
      }

      if (this.geolink) {
        this.geolink.geometry = {
          type: 'LineString',
          coordinates: [this.myPosition, strArrToNumberArr(this.target.FeatureGeometry.Point)]
        };
        this.map.updateFeature(this.geolink);
      }
    }
  };

  getAdderFunction = (pAnnotation) => {
    const lSelfStyledIndex = this.props.selfStyledObjects.indexOf(pAnnotation.type);
    let lAdder = null;

    // Only display items with 'Maps' tag
    if (pAnnotation.tags.includes('Maps')) {
      if (lSelfStyledIndex >= 0) {
        lAdder = addSelfStyledObject;
      } else if (pAnnotation.FeatureElement && pAnnotation.FeatureElement.Geometry) {
        lAdder = addGeoAnnotation;
      }

      // Hack : do not display your own IdActorPos
      if (pAnnotation.ActorPath === KeycloakManager.getUser().preferred_username) {
        lAdder = null;
      }
    }

    return lAdder;
  };

  /**
   * Add an annotation to the map, given the way it is supposed to be drawn.
   * Store it locally for reference / updates
   *
   * @param {Object} pAnnotation The annotation to add to the map.
   */
  addAnnotation = (pAnnotation) => {
    try {
      const lAdder = this.getAdderFunction(pAnnotation);

      if (typeof lAdder === 'function') {
        // In some case, the annotation might has been added "locally" to the MapManager, so first remove it
        this.removeAnnotation(pAnnotation);

        const lData = lAdder(pAnnotation, this);

        this.annotationsData[pAnnotation.Uuid] = lData;
      }
    } catch (err) {
      console.error('Error in MapManagerContext->addAnnotation', err);
    }
  };

  /**
   * Remove an annotation and its associated data.
   *
   * @param  {Object} pAnnotation The annotation to be removed.
   */
  removeAnnotation = (pAnnotation) => {
    const lAnnotationId = pAnnotation.Uuid;
    if (this.annotationsData[lAnnotationId]) {
      const lStyle = this.annotationsData[lAnnotationId].style;
      const lFeature = this.annotationsData[lAnnotationId].feature;

      this.map.removeFeature(lFeature);
      this.map.removeStyle(lStyle.name);

      delete this.annotationsData[lAnnotationId];
    }
  };

  /**
   * Update the geolinks
   * @param {*} param0
   */
  updateGeoLinks({ annotations, currentUserTeamInfo, teammates }) {
    if (currentUserTeamInfo.isLeader) {
      // Update geolink for each teammates
      teammates.forEach((tm) => {
        const target = annotations.find((a) => a.type === 'GeoSymbol' && a.assignedTo === tm.ActorID);
        if (target) {
          const geolinkGeometry = {
            type: 'LineString',
            coordinates: [strArrToNumberArr(tm.FeatureGeometry.Point), strArrToNumberArr(target.FeatureGeometry.Point)]
          };
          if (isNaN(tm.FeatureGeometry.Point[0]) || isNaN(tm.FeatureGeometry.Point[1])) {
            geolinkGeometry.coordinates[0] = geolinkGeometry.coordinates[1];
          }
          if (!this.geolinks[tm.Uuid]) {
            this.geolinks[tm.Uuid] = {
              featureId: tm.Uuid,
              layerId: 'GEOLINKS',
              styleName: 'GEOLINK_STYLE',
              geometry: geolinkGeometry
            };
            this.map.addFeature(this.geolinks[tm.Uuid]);
          } else {
            this.geolinks[tm.Uuid].geometry = geolinkGeometry;
            this.map.updateFeature(this.geolinks[tm.Uuid]);
          }
        } else if (this.geolinks[tm.Uuid]) {
          this.map.removeFeature(this.geolinks[tm.Uuid]);
          delete this.geolinks[tm.Uuid];
        }
      });

      // Check if some teammates have been deleted to remove also the geolinks
      for (const geolinkId in this.geolinks) {
        const associatedTeammate = teammates.find((tm) => tm.Uuid === geolinkId);
        if (!associatedTeammate) {
          this.map.removeFeature(this.geolinks[geolinkId]);
          delete this.geolinks[geolinkId];
        }
      }
    } else {
      const { sub: currentUserId } = KeycloakManager.getUser();
      this.target = annotations.find((a) => a.type === 'GeoSymbol' && a.assignedTo === currentUserId);
      if (this.target) {
        const targetCoord = strArrToNumberArr(this.target.FeatureGeometry.Point);
        const geolinkGeometry = {
          type: 'LineString',
          coordinates: [this.myPosition || targetCoord, targetCoord]
        };
        if (!this.geolink) {
          this.geolink = {
            featureId: this.target.Uuid,
            layerId: 'GEOLINKS',
            styleName: 'GEOLINK_STYLE',
            geometry: geolinkGeometry
          };
          this.map.addFeature(this.geolink);
        } else {
          this.geolink.geometry = geolinkGeometry;
          this.map.updateFeature(this.geolink);
        }
      } else if (this.geolink && !this.target) {
        this.map.removeFeature(this.geolink);
        this.geolink = null;
        this.target = null;
      }
    }
  }

  /**
   * Diff previous annotations with current annotations
   * and add / remove from map accordingly.
   */
  componentDidUpdate(prevProps) {
    if (this.state.mapReady) {
      const annotations = Object.values(this.props.annotations);

      // Hack for busy management, default is false and the server do not return the default value
      annotations.forEach((a) => {
        if (a.type === 'Team' || a.type === 'Teammate') {
          if (!a.hasOwnProperty('Busy')) {
            a.Busy = false;
          }
        }
      });

      // Hack for team type
      const teams = annotations.filter((a) => a.type === 'Team');
      const teammates = annotations.filter((a) => a.type === 'Teammate');
      teammates.forEach((tm) => {
        const team = teams.find((t) => t.TeammatesUuids && t.TeammatesUuids.indexOf(tm.Uuid) >= 0);
        if (team) {
          tm.TeamType = team.TeamType;
        }
      });

      // Manage "geolink" update
      this.updateGeoLinks({
        annotations,
        currentUserTeamInfo: this.props.currentUserTeamInfo,
        teammates: this.props.teammates
      });

      // Manage map updates
      const updates = annotations.reduce(
        (acc, annotation) => {
          const isNew = !prevProps.annotations.hasOwnProperty(annotation.Uuid);

          const hasChanged =
            prevProps.annotations[annotation.Uuid] &&
            (getAnnotationVersion(annotation) !== getAnnotationVersion(prevProps.annotations[annotation.Uuid]) ||
              // The TooOld field is generated client side and the server is not aware of it.
              // Thus we need to do a specific check on it for this change, as the annotation
              // version would not be updated
              prevProps.annotations[annotation.Uuid].TooOld !== annotation.TooOld);

          return {
            add: [...acc.add, ...(isNew || hasChanged ? [annotation] : [])],
            remove: [...acc.remove, ...(hasChanged ? [annotation] : [])]
          };
        },
        {
          add: [],
          remove: []
        }
      );

      Object.values(prevProps.annotations).forEach((annotation) => {
        if (!this.props.annotations.hasOwnProperty(annotation.Uuid)) {
          updates.remove = [...updates.remove, annotation];
        }
      });

      updates.remove.forEach((annotation) => {
        this.removeAnnotation(annotation);
      });

      updates.add.forEach((annotation) => {
        this.addAnnotation(annotation);
      });
    }
  }

  drawToolHook = (pFeature) => {
    if (!this.callbackFunc) {
      return;
    }

    this.callbackFunc(pFeature.geometry);
    this.callbackFunc = null;
    if (this.removeFeature) {
      this.map.removeFeature(pFeature);
    }
  };

  measureToolHook = (pMeasureToolData) => {
    this.map.stopTool();
    if (this.callbackFunc && pMeasureToolData) {
      this.callbackFunc(pMeasureToolData.geometry, pMeasureToolData.measureData);
      this.callbackFunc = null;
    }
  };

  componentWillUnmount() {
    this.dispose(true);
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const withMapManager = (BaseComponent) => {
  return class extends Component {
    render() {
      return <Consumer>{(MapManager) => <BaseComponent MapManager={MapManager} {...this.props} />}</Consumer>;
    }
  };
};

const AnnotatedMapManagerProvider = withAnnotations({
  shouldBeVisible: ({ annotation, team }) =>
    // We do not want to display comments on the map,
    // so they should never be included in the annotations passed to this component
    annotation.type !== 'Comment' &&
    // Do not display incident with closed status
    (annotation.type === 'Incident' || annotation.type === 'IncidentMission' ? annotation.State !== 3 : true) &&
    // Do not display teammate from other teams
    (annotation.type === 'Teammate'
      ? team && team.TeammatesUuids && team.TeammatesUuids.indexOf(annotation.Uuid) >= 0
      : true)
})(MapManagerProvider);

export { AnnotatedMapManagerProvider as MapManagerProvider, withMapManager };
