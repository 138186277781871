import i18n from 'i18next';
// import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector';

//TODO: use the react-i18next module ?
// Load directly the resources into the JS, to avoid some additional loading
// if translations are too heavy, we can use the XHR backend (commented)
import frResources from 'locales/fr-FR/common.json';
import enResources from 'locales/en/common.json';

i18n.use(LanguageDetector).init({
  resources: {
    fr: {
      common: frResources
    },
    en: {
      common: enResources
    }
  },

  fallbackLng: 'en',

  returnEmptyString: false,

  react: {
    useSuspense: false
  },

  /* backend:  {
			loadPath: 'locales/{{lng}}/{{ns}}.json',
		}, */

  ns: ['common'],
  defaultNS: 'common',

  debug: false
});

export default i18n;
export const _t = (s, values) => i18n.t(s, values);
