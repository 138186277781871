import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
import { useTheme, withStyles } from '@mui/styles';
import { Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Toolbar, Typography, IconButton } from '@mui/material';
import { Group, Settings, ExitToApp, Info, ViewHeadline } from '@mui/icons-material';
import { AppConfigContext } from 'AppConfig';
import SettingsManager from 'services/SettingsManager';
import { compose } from 'utils';
import { _t } from 'utils/i18n';
import {  ArrowBack } from '@mui/icons-material';

const styles = (theme) => ({
  drawerInnerWeb: {
    width: '16rem',
    [theme.breakpoints.up('md')]: {
      width: '20rem'
    },
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '40px'
  },
  titleButton: {
    color: theme.palette.secondary.contrastText
  },
});

/**
 * Main view.
 */
const MainDrawer = ({
  classes,
  session,
  history,
  onChangeSession,
  onClose,
  onLogout,
  onOpen,
  open,
  user,
  setDrawerOpen,
  setWidgetStatus,
  toggleAboutDialog
}) => {
  const [ListItems, setListItems] = React.useState(null);
  const appConfig = React.useContext(AppConfigContext);
  const theme = useTheme();

  React.useEffect(() => {
    // Build the item lists for the drawer
    const newListItems = [];

    for (const [lIndex, Component] of appConfig.mainDrawerItems.entries()) {
      newListItems.push(
        <div key={lIndex}>
        <Component
          key={lIndex}
          handleListChange={(pValue) => {
            history.push(pValue);
          }}
          history={history}
          setWidgetStatus={setWidgetStatus}
        />
        <Divider/>
        </div>
      );
    }

    setListItems(newListItems);
  }, [history, setWidgetStatus, appConfig, setDrawerOpen]);

  return (
    <SwipeableDrawer
      disableBackdropTransition
      disableDiscovery
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      classes={{
        paper: classes.drawerInnerWeb
      }}
      sx={{zIndex: 1600}}
    >
      <Toolbar key={'toolbar'} className={classes.toolbar}>
        <div className={classes.title}>
          <Typography variant="subtitle2">{session.title}</Typography>
          <Typography variant="caption">{user}</Typography>
        </div>
        
        <IconButton onClick={() => setDrawerOpen(false)} size="large">
          <ArrowBack sx={{color: theme.palette.primary.contrastText}}/>
        </IconButton>
      </Toolbar>

      {/* List of items for drawer */}
      <List defaultValue={0} disablePadding>
        {ListItems}
      </List>

      {/* Permanent menu items  */}
      <List disablePadding>

          <ListItem key={'teams'} button component={Link} to="/team" >
            <ListItemIcon className={classes.titleButton}>
              <Group />
            </ListItemIcon>
            <ListItemText primary={_t('Teams')} />
          </ListItem> 

        {/* Only display the change session action if no default session in the settings */}
        {!SettingsManager.defaultSession && 
          <ListItem
            key={'sessions'}
            button
            onClick={() => {
              setDrawerOpen(false);
              onChangeSession();
            }}
          >
          <ListItemIcon className={classes.titleButton}>
              <ViewHeadline />
            </ListItemIcon>       
            <ListItemText primary={_t('Sessions')} />
          </ListItem> 
        }

        <ListItem key={'settings'} button component={Link} to="/settings">
          <ListItemIcon className={classes.titleButton}>
              <Settings />
            </ListItemIcon>
          <ListItemText primary={_t('Settings')} />
        </ListItem>

        <ListItem
          key={'about'}
          button
          onClick={() => {
            setDrawerOpen(false);
            toggleAboutDialog();
          }}
        >
          <ListItemIcon className={classes.titleButton}>
              <Info />
          </ListItemIcon>
          <ListItemText primary={_t('About')} />
        </ListItem>

        <ListItem button key={'logOut'}>
          <ListItemIcon className={classes.titleButton}>
              <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={_t('Log Out')} onClick={onLogout} />
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default compose(React.memo, withStyles(styles), withRouter)(MainDrawer);
