import { KeycloakManager, SettingsManager } from 'services';

/**
 * Manage "actors" : keep a list of users which can connect in the system.
 * Used to retrieve user information.
 */
class ActorManager {
  constructor() {
    this.actors = {};
  }

  /**
   * Poll users from keycloak to always have a correct list.
   */
  pollUsers = () => {
    KeycloakManager.getUsersList()
      .then((pResult) => {
        this.actors = pResult.reduce((actors, actor) => {
          return {
            ...actors,
            [actor.id]: {
              username: actor.username,
              firstname: actor.firstName || '',
              lastname: actor.lastName || '',
              uuid: actor.id
            }
          };
        }, {});
      })
      .catch(console.error);
  };

  /**
   * Called to register the actors
   */
  register() {
    // Regularly poll users from the KeycloakManager
    this.pollUsers();
    this.mRefreshIntervalId = setInterval(this.pollUsers, SettingsManager.actorManager.refreshInterval * 1000);
  }

  /**
   * Called to unregister
   */
  unregister() {
    clearInterval(this.mRefreshIntervalId);
  }

  /**
   * Get an actor by its identifier
   */
  getActor = (pId) => this.actors[pId];

  getActorByUsername(pUsername) {
    for (const actor in this.actors) {
      if (this.actors[actor].username === pUsername) return this.actors[actor];
    }
  }
}

export default new ActorManager();
